.service-entry {
  @media (max-width: $lg-break) {
    padding: 0 16px;
  }

  .service-title {
    color: $colorAccent1;
    line-height: 1;
  }

  .service-text {
    color: $white;
  }

  .content-container {
    display: flex;
    justify-content: space-between;
    border-top: 1.5px solid white;
    padding-top: 120px;
    padding-bottom: 120px;

    @media (max-width: 666px) {
      flex-wrap: wrap;
    }

    @media (max-width: $sm-break) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .content-text {
    max-width: 50%;

    @media (max-width: $lg-break) {
      max-width: 60%;
    }

    @media (max-width: $md-break) {
      max-width: 100%;
    }

    .service-text {
      ul {
        padding-left: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        list-style-type:none;


        li {
          font-family: $fontText;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 155%;
          width: calc((100% / 2) - 15px);
          margin-right: 30px;
          margin-bottom: 30px;

          &::before {
            content: "//  ";
            color: $colorAccent1;
            font-family: $fontText;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 155%;
          }

          &:nth-child(2n+2) {
            margin-right: 0px;
          }

          @media (max-width: 666px) {
            width: 100%;
            max-width: 51%;
            margin: 0 auto;
            margin-bottom: 30px;


            &:nth-child(2n+2) {
              margin: 0 auto;
              margin-bottom: 30px;
            }
          }

          @media (max-width: $md-break) {
            margin: 0 auto;
            max-width: 256px;
            margin-bottom: 30px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          strong {
            color: $colorAccent1;
          }
        }
      }
    }
  }

  .content-heading {
    display: flex;
    align-items: flex-start;
    position: relative;
    max-width: 50%;

    @media (max-width: $lg-break) {
      width: 190px;
      height: 130px;
      align-items: center;
    }

    @media (max-width: $md-break) {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 88px;
      max-width: 256px;
    }

    .img-wrapper {
      position: absolute;
      top: -50px;
      left: 0;
      width: 190px;
      max-width: 190px;
      z-index: 1;

      @media (max-width: $lg-break) {
        top: 0;
        left: -15px;
        transform: inherit;
      }

      img {
        display: block;
        width: 100%;
      }
    }

    .service-title {
      margin-left: 110px;
      z-index: 2;

      @media (max-width: $md-break) {
        margin-left: 0;
      }
    }
  }
}
