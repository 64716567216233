.home-hero {
  position: relative;
  min-height: calc(100vh - 106px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: $md-break) {
    height: calc(100vw - 144px);
  }

  .hero-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    .hero-animation-zone {
      position: relative;
      width: 100%;
      height: 200px;
      max-width: 100%;
      overflow: hidden;

      @media (max-width: $md-break) {
        height: 135px;
      }

      .animation-image {
        position: absolute;
        top: 0;
        left: 10vw;
        height: 100%;
        transition: transform ease-out .2s;

        &.is-hidden {
          opacity: 0;
        }
      }
    }

    .hero-title {
      color: white;
      max-width: 700px;

      @media (max-width: $md-break) {
        max-width: 520px;
        margin-bottom: 60px;
      }
    }

    .hero-btn {
      display: none;

      @media (max-width: $md-break) {
        display: block;
        max-width: 166px;
        background-color: $colorAccent1;
        color: $colorAccent2;
        border-color: $colorAccent1;
      }
    }
  }

  .bottom-content {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 38px;

    @media (max-width: 767px) {
      display: none;
    }
  }
}

.social-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 175px;
}

.hero-tag-link {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $colorAccent1;
  background-color: rgba($colorAccent1, .2);
  border: 1px solid $colorAccent1;
  border-radius: 3px;
  padding: 4px 8px;

  &:after {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: $colorAccent1;
    border-radius: 50%;
    margin-left: 7px;
  }
}

.global-hero {
  position: relative;
  padding-top: 60px;
  padding-bottom: 150px;

  @media (max-width: $md-break) {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .content-container {
    display: flex;
    justify-content: space-between;
  }

  .global-hero-title {
    color: $colorAccent1;
    max-width: 658px;

    font-size: 72px;
    font-style: normal;
    font-weight: 600;
    line-height: 86px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 55px;

    @media (max-width: $md-break) {
      max-width: 100%;
    }

    @media (max-width: $sm-break) {
      max-width: 100%;
      font-size: 40px;
      line-height: 55px;
      margin-bottom: 32px;
    }
  }

  .global-hero-text {
    color: $white;
    max-width: 640px;

    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;

    margin-right: 0;

    p {
      margin: 0;
    }


    @media (max-width: $md-break) {
      max-width: 100%;
    }

    @media (max-width: $sm-break) {
      max-width: 100%;
    }
  }

  .image {
    margin-top: 20px;

    .image-container {
        position: relative;
    }

    .rotate-img {
      display: block;
      width: 150px;
      height: 150px;
      animation:spin 16s linear infinite;

      @media (max-width: $md-break) {
        display: none;
      }
    }

    .macaron-text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      color: $colorAccent1;
      text-align: center;

      @media (max-width: $md-break) {
        display: none;
      }
    }
  }


}

@keyframes scroll{
  0%   {transform: translateX(0);}
  100% {transform: translateX(-100%);}
}


