.hoffman-life-blog-hero {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  &.is-job-hero {
    justify-content: flex-start;
    min-height: 50vh;
    padding: 164px 0 0;

    width: 100%;
    max-width: calc(1120px + 2 * 40px);
    margin: 0 auto;

    @media (max-width: 767px) {
      padding: 132px 0 0;
    }

    .hero-back-link-container {
      .hero-back-link {
        span {
          color: $white;
        }
      }
    }

    .hero-content {
      width: 100%;

      .content-container {
        max-width: 100%;
        padding: 40px;

        @media (max-width: 767px) {
          padding: 16px;
        }

        .hero-heading {
          display: block;
          width: 100%;
          font-family: $fontTitle;
          font-size: 96px;
          font-weight: 600;
          line-height: 106px;
          letter-spacing: 0em;
          text-align: left;
          color: $colorAccent1;
          max-width: 940px;
          text-transform: none;
          margin-bottom: 61px;

          &.is-job {
            @media (max-width: 767px) {
              font-size: 40px;
              font-weight: 600;
              line-height: 55px;
              letter-spacing: 0em;
            }
          }
        }

        .hero-description {
          display: block;
          width: 100%;
          max-width: 835px;
          font-family: $fontTitle;
          font-size: 36px;
          font-weight: 400;
          line-height: 49px;
          letter-spacing: 0em;
          text-align: left;

          &.is-job {
            @media (max-width: 767px) {
              font-size: 24px;
              font-weight: 400;
              line-height: 33px;
              letter-spacing: 0em;

            }
          }
        }
      }
    }

    .hero-image-container {
      display: none;
    }
  }

  .hero-back-link-container {
    position: absolute;
    top: 50px;
    width: 100%;
    max-width: 1152px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    z-index: 2;
    pointer-events: none;
    padding: 0 16px;

    @media (max-width: 767px) {
      top: 35px;
    }

    .hero-back-link {
      display: flex;
      align-items: center;
      pointer-events: auto;

      svg {
        height: 12px;
        margin-right: 10px;
      }

      span {
        font-family: $fontTitle;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 135%;
        color: $colorAccent2;
      }
    }
  }

  .hero-image-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: calc(50% + 80px);
    display: flex;

    @media (max-width: 1200px) {
      width: 40%;
    }

    @media (max-width: 767px) {
      position: relative;
      width: 100%;
      bottom: initial;
      height: 105vw;
      max-height: 500px;
    }

    .hero-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .hero-content {
    width: calc(50% - 80px);
    display: flex;

    @media (max-width: 1200px) {
      width: 60%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }

    .content-container {
      width: 100%;
      max-width: 500px;
      padding: 40px 40px 40px 64px;
      display: flex;
      flex-direction: column;
      margin: 0;

      @media (max-width: 1200px) {
        max-width: 100%;
        padding: 40px;
      }

      @media (max-width: 767px) {
        padding: 32px 16px 0;
      }

      .hero-heading {
        font-family: $fontTitle;
        font-style: normal;
        font-weight: 300;
        font-size: 70px;
        line-height: 110%;
        text-transform: uppercase;
        color: $white;
        margin: 0 0 50px;

        @media (max-width: 1200px) {
          font-size: 50px;
        }

        @media (max-width: 767px) {
          margin-bottom: 20px;
        }
      }

      .hero-description {
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 155%;
        color: $white;
        margin: 0;
      }
    }
  }
}
