.block-jobs {
  background-color: $white;
  padding-top: 164px;
  padding-bottom: 156.5px;

  @media (max-width: $md-break) {
    padding-top: 108px;
    padding-bottom: 120.5px;
  }

  @media (max-width: $sm-break) {
    padding-top: 124px;
    padding-bottom: 89.5px;
  }



  .content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @media (max-width: $md-break) {
      flex-direction: column;
      align-items: flex-start;
    }

    .jobs-text {
      color: #959595;
      max-width: 544px;

      @media (max-width: $md-break) {
        width: 100%;
        max-width: 100%;

      }

      p {
        margin: 0;
      }
    }

    .jobs-title {

      font-family: $fontTitle;
      font-size: 72px;
      font-style: normal;
      font-weight: 600;
      line-height: 86px;
      letter-spacing: 0em;
      text-align: left;


      @media (max-width: $md-break) {
        width: 100%;
        margin-bottom: 46px;
        max-width: 403px;

        font-size: 72px;
        font-style: normal;
        font-weight: 600;
        line-height: 86px;
        letter-spacing: 0em;
        text-align: left;
      }

      @media (max-width: $sm-break) {

        max-width: 224px;

        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 55px;
        letter-spacing: 0em;
        text-align: left;

      }
    }
  }

  .spontaneous-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 119px;
    padding-bottom: 118px;
    border-bottom: 1.5px solid $dark;


    @media (max-width: $md-break) {
      flex-direction: column;
      align-items: flex-start;
    }

    .form-wrapper {
      position: relative;


      &.is-active {
        display: block;
        min-height: 500px;
        width: 100%;
      }
    }

    .spontaneous-title {
      display: block;
      width: 50%;

      @media (max-width: $md-break) {
        width: 100%;
        max-width: 483px;


        font-size: 72px;
        font-style: normal;
        font-weight: 600;
        line-height: 86px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 61px;

      }

      @media (max-width: $sm-break) {
        width: 100%;
        max-width: 291px;


        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 55px;
        letter-spacing: 0em;
        text-align: left;
      }
    }

    .spontaneous-text {
      color: #959595;
      max-width: 544px;
      width: 50%;

      @media (max-width: $md-break) {
        width: 100%;
        max-width: 100%;
      }

      @media (max-width: $sm-break) {

      }

      p {
        margin: 0;


        @media (max-width: $md-break) {
          font-size: 24px;
          font-style: normal;
          font-weight: 300;
          line-height: 34px;
          letter-spacing: 0em;
          text-align: left;
        }

        @media (max-width: $sm-break) {

          font-size: 24px;
          font-style: normal;
          font-weight: 300;
          line-height: 34px;
          letter-spacing: 0em;
          text-align: left;

        }
      }

      a {
        margin-top: 26px;

        @media (max-width: $md-break) {
          margin-top: 57px;

          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: left;

        }

        @media (max-width: $sm-break) {
          margin-top: 34px;

          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }

    .spontaneous-btn {
      display: flex;
      align-items: center;


      font-family: $fontTitle;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;


      svg {
        margin-right: 11px;
      }
    }
  }

  .accordeon-jobs-wrapper {
    margin-top: 113px;
    border-top: 1.5px solid $dark;

    .job-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-top: 36px;
      padding-bottom: 36px;
      border-bottom: 1.5px solid $dark;

      .job-title {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 34px;
        letter-spacing: 0em;

        min-width: 350px;

        .is-new {
          background: #FFE600;
          border-radius: 40px;
          font-family: $fontTitle;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          padding: 3px 12px;
          margin-left: 10px;

          @media (max-width: $md-break) {
            display: none;
          }
        }
      }

      .job-city {
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 28px;
        letter-spacing: 0em;
      }

      .job-time {
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 28px;
        letter-spacing: 0em;
      }

      .job-btn {
        display: flex;
        align-items: center;
        color: $dark;
        border-color: $dark;
        position: relative;
        border: 1px solid $dark;

        span {
          margin-right: 11px;
        }

        .not-hover {
          display: block;
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translate(0, -50%);
          opacity: 1;
        }

        .is-hover {
          display: block;
          opacity: 0;
        }

        &:hover {
          background: $white;
          color: $dark;

          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: center;

          border: 1.5px solid $dark;

          .not-hover {
            top: 35%;
            transform: rotate(90deg);
          }
        }

        &:active {
          background: $dark;
          color: $colorAccent1;

          .not-hover {
            opacity: 0;
          }

          .is-hover {
            opacity: 1;
          }
        }
      }

      @media (max-width: $xl-break) {
        .job-title {
          min-width: auto;
          max-width: auto;
        }

        .job-city {
          display: none;
        }

        .job-time {
          display: none;
        }

      }

      @media (max-width: $sm-break) {
        align-items: center;

        .job-title {
          min-width: auto;
          max-width: 164px;
        }
      }
    }
  }
}
