.hoffman-life-slider {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $colorAccent2;

  &.just-for-one .slider-container {
    .slider-heading {
      margin-bottom: 0;
    }

    .slider-items {
      .flickity-page-dots,
      .slider-item:before {
        display: none;
      }
    }
  }

  .slider-container {
    width: 100%;
    max-width: calc(1110px + (2 * 40px));
    padding: 100px 40px;
    display: flex;
    flex-direction: column;

    @media (max-width: 1023px) {
      padding: 80px 40px;
    }

    @media (max-width: 767px) {
      padding: 30px 16px;
    }

    .slider-heading {
      font-family: $fontTitle;
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 135%;
      color: $white;
      margin: 0 0 70px;

      @media (max-width: 1023px) {
        font-size: 32px;
      }

      @media (max-width: 767px) {
        font-size: 24px;
        margin-bottom: 50px;
      }
    }

    .slider-filters {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 48px;

      .slider-filter {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 155%;
        color: rgba($light, 0.5);
        margin: 0 32px 32px 0;
        cursor: pointer;

        &.is-active {
          color: $colorAccent1;
        }
      }
    }

    .slider-items {
      width: 100%;
      display: flex;
      padding-bottom: 70px;

      @media (max-width: 1023px) {
        padding-bottom: 40px;
      }

      @media (max-width: 1023px) {
        padding-bottom: 75px;
      }

      .flickity-viewport {
        width: 100%;
        overflow: visible;
      }

      .flickity-page-dots {
        bottom: 0;

        .dot {
          background-color: rgba($white, 0.4);
          margin: 0 5px;

          &.is-selected {
            background-color: $white;
          }
        }
      }

      .slider-item {
        position: relative;
        width: 75%;
        margin-right: 100px;
        display: flex;
        justify-content: flex-end;
        margin-top: 120px;
        opacity: 0;
        transition: all ease-out 0.2s;

        @media (max-width: 1023px) {
          width: 90%;
          margin-right: 40px;
          margin-top: 80px;
        }

        @media (max-width: 767px) {
          margin-right: 16px;
          margin-top: 75px;
        }

        &:before {
          content: "";
          position: absolute;
          top: -120px;
          left: 0;
          height: 160px;
          width: calc(25% + 100px);
          background-position: center right;
          background-repeat: no-repeat;
          background-size: auto 100%;
          opacity: 0;
          transition: all ease-out 0.6s;
          transition-delay: 0.2s;

          @media (max-width: 1023px) {
            top: -80px;
            height: 120px;
          }

          @media (max-width: 767px) {
            width: 100%;
            background-position: left center;
            top: -75px;
            height: 100px;
          }
        }

        &:nth-child(1):before {
          background-image: url("/dist/img/slider-1.svg");
        }
        &:nth-child(2):before {
          background-image: url("/dist/img/slider-2.svg");
        }
        &:nth-child(3):before {
          background-image: url("/dist/img/slider-3.svg");
        }
        &:nth-child(4):before {
          background-image: url("/dist/img/slider-4.svg");
        }
        &:nth-child(5):before {
          background-image: url("/dist/img/slider-5.svg");
        }
        &:nth-child(6):before {
          background-image: url("/dist/img/slider-6.svg");
        }

        .slider-image-wrapper {
          width: 75%;
          display: flex;
          transform: translateX(-33%);
          transition: all ease-out 0.8s;

          @media (max-width: 767px) {
            width: 85%;
            transform: translateX(-17%);
          }

          .slider-image-container {
            position: relative;
            width: 100%;
            padding-top: 63%;

            .slider-image {
              position: absolute;
              inset: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }

        .slide-container {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(100px, -50%);
          opacity: 0;
          transition: all ease-out 0.2s;
          width: 490px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          @media (max-width: 1023px) {
            width: 350px;
          }

          @media (max-width: 767px) {
            width: 245px;
            top: initial;
            bottom: -35px;
            transform: translate(100px, 0);
          }

          .slide-heading {
            font-family: $fontTitle;
            font-style: normal;
            font-weight: 600;
            font-size: 80px;
            line-height: 115%;
            color: $white;
            margin: 0 0 30px;

            @media (max-width: 1023px) {
              font-size: 50px;
              margin-bottom: 20px;
            }

            @media (max-width: 767px) {
              font-size: 36px;
            }
          }
        }

        &.is-selected {
          opacity: 1;

          &:before {
            opacity: 1;
          }

          .slider-image-wrapper {
            transform: translateX(0);
          }

          .slide-container {
            transform: translate(0, -50%);
            opacity: 1;
            transition-duration: 1s;
            transition-delay: 0.4s;

            @media (max-width: 767px) {
              transform: translate(0, 0);
            }
          }

          & + .slider-item {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
