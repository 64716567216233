.block-team {
  background-color: $colorAccent2;
  padding-top: 165px;
  padding-bottom: 55px;

  @media (max-width: $md-break) {
    padding-top: 165px;
    padding-bottom: 0px;
  }

  @media (max-width: $sm-break) {
    padding-top: 108px;
    padding-bottom: 0px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 67px;
    padding-bottom: 100px;
    border-bottom: 1px solid $white;
    border-top: 1px solid $white;

    @media (max-width: $lg-break) {
      padding-top: 101px;
      padding-bottom: 160px;
    }

    @media (max-width: $md-break) {
      padding-top: 96.5px;
      padding-bottom: 160px;
    }

    .team-title {
      display: block;
      width: 100%;
      margin-bottom: 72px;
      color: $white;

      @media (max-width: $md-break) {
        margin-bottom: 56px;
        order: 2;
      }
    }

    .team-text {
      margin-bottom: 50px;

      @media (max-width: $md-break) {
        margin-bottom: 20px;
      }
    }

    .img-wrapper {
      position: relative;
      width: 150px;
      height: 150px;

      @media (max-width: $lg-break) {
        margin: 0 auto;
        margin-right: 0;
        margin-top: -80px;
        order: 4;
      }

      .macaron-text {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        color: $colorAccent1;

        &:after {
          display: none;
        }
      }

      .macaron {
        display: block;
        animation:spin 16s linear infinite;
      }
    }



    .content-text {
      display: block;
      max-width: 640px;

      p {
        color: $white;
        margin-bottom: 11px;

        @media (max-width: $lg-break) {
          margin-bottom: 104px;
        }
      }

      .team-btn {
        color: $colorAccent1;

        &:after {
          background-color: $colorAccent1;
        }
      }

      @media (max-width: $md-break) {
        width: 100%;
        order: 3;
      }
    }
  }


}

.team-entry-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 200px;

  @media (max-width: $lg-break) {
    padding-top: 27px;
    padding-bottom: 155px;
  }

  @media (max-width: $md-break) {
    padding-top: 20px;
  }

  .team-entry {
    width: calc(100% / 2 - 16.5px);
    margin-right: 33px;
    margin-bottom: 142px;

    @media (max-width: $md-break) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 80px;
    }

    &:nth-child(2n+2) {
      margin-right: 0;
    }

    .content-image {
      max-width: 100%;
      margin-bottom: 40px;

      img {
        width: 100%;
        display: block;
        max-width: 100%;
      }
    }

    .content-heading {
      padding-bottom: 18px;
      border-bottom: 1px solid $white;
      .member-name {
        color: $colorAccent1;
      }

      .heading-add-infos {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .member-job {
          color: $white;
        }
      }
    }

    .content-text {
      padding-top: 25px;

      .member-description {
        color: #959595;

        p {
          margin: 0;
        //styleName: Body 24px Light;
          font-family: $fontText;
          font-size: 24px;
          font-style: normal;
          font-weight: 300;
          line-height: 34px;
          letter-spacing: 0em;
          text-align: left;

        }
      }
    }
  }
}


@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg); transform:rotate(360deg);
  }
}
