.block-vision {
  background-color: $colorAccent1;
  padding-top: 164px;
  padding-bottom: 175px;

  @media (max-width: $md-break) {
    padding-top: 83px;
    padding-bottom: 122px;
  }

  @media (max-width: $sm-break) {
    padding-top: 60px;
    padding-bottom: 100px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .vision-title {
      display: block;
      border-bottom: 1.5px solid black;
      padding-bottom: 39px;
      width: 100%;
      margin-bottom: 96px;

      @media (max-width: $md-break) {
        margin-bottom: 56px;
        order: 2;
      }
    }

    .img-wrapper {
      position: relative;
      width: 150px;
      height: 150px;

      @media (max-width: $md-break) {
        margin: 0 auto;
        margin-right: 0;
        order: 1;
      }

      .img-fix {
        display: block;
        position: absolute;
        max-width: 90px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .macaron {
        display: block;
        width: 100%;
        animation:spin 16s linear infinite;
      }
    }



    .content-text {
      display: block;
      max-width: 640px;

      @media (max-width: $md-break) {
        width: 100%;
        order: 3;
      }
    }
  }


}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg); transform:rotate(360deg);
  }
}
