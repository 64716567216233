.block-clients {
  width: 100%;
  background-color: $colorAccent2;
  padding-top: 112px;
  padding-bottom: 195px;


  @media (max-width: $md-break) {
    padding-top: 193px;
    padding-bottom: 185px;
  }

  @media (max-width: $sm-break) {
    padding-top: 100.5px;
    padding-bottom: 129.86px;
  }

  .logo {
    display: block;
    width: 100%;
    max-width: 272px;
    margin: 0 auto;
    margin-bottom: 99px;

    @media (max-width: $md-break) {
      max-width: 279px;
      margin-bottom: 110px;
    }

    @media (max-width: $sm-break) {
      max-width: 242px;
      margin-bottom: 70px;
    }
  }

  .clients-text {
    display: none;
    text-align: center;
    color: $light;
    max-width: 361px;
    margin: 0 auto;
    margin-bottom: 105px;

    &.is-tech {
      display: block;
      font-size: 72px;
      font-weight: 600;
      line-height: 86px;
      letter-spacing: 0em;
      text-align: left;
      color: $white;
      width: 100%;
      max-width: calc(1110px + (2 * 40px));
      margin: 0;
      padding: 0;
      margin-bottom: 67px;

      @media (max-width: 767px) {
        font-size: 36px;
        font-weight: 600;
        line-height: 49px;
        letter-spacing: 0em;
        margin-bottom: 32px;
      }

      p {
        em {
          background-color: $departmentBlue;
          font-style: normal;
        }
      }
    }
  }

  .scrolling-wrapper {

    .markee-wrapper {
      margin-left: 50px;

      &:hover {
        .markee-logo {
          filter: grayscale(0%);
        }
      }


      .markee-logo {
        display: block;
        width: 100%;
        min-width: 159px;
        max-width: 159px;
        transition: filter .35s ease-in-out;
        filter: grayscale(100%);

        &.no-grey {
          filter: none;
        }
      }
    }
  }
}
