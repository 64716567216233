.featured-project {
  width: 100%;
  display: flex;
  flex-direction: column;

  .project-main-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }

    .content-container {
      position: absolute;
      top: 50%;
      left: 96px;
      width: 353px;
      transform: translateY(-50%);
      padding: 0;

      @media (max-width: 1023px) {
        left: 47px;
      }

      @media (max-width: 767px) {
        position: relative;
        top: initial;
        left: initial;
        width: 100%;
        transform: none;
        padding: 26px 26px 40px;
      }

      .project-heading {
        font-family: $fontTitle;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 41px;
        color: #FFFFFF;
        margin: 12.5px 0;

        p {
          margin: 0;
        }
      }

      .project-text {
        font-family: Helvetica, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 155%;
        color: #FFFFFF;
        margin: 12.5px 0;

        p {
          margin: 0;
        }
      }
    }

    .image-container {
      display: flex;

      img {
        height: 100%;
        max-height: 571px;

        @media (max-width: 1023px) {
          max-height: 450px;
        }

        @media (max-width: 767px) {
          width: 100%;
          max-height: 100%;
        }
      }

      .image-desktop {
        @media (max-width: 1023px) {
          display: none;
        }
      }

      .image-tablet {
        display: none;

        @media (max-width: 1023px) {
          display: block;
        }

        @media (max-width: 767px) {
          display: none;
        }
      }

      .image-mobile {
        display: none;

        @media (max-width: 767px) {
          display: block;
        }
      }
    }
  }

  .project-infos {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 45px;

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    .project-name {
      font-family: $fontTitle;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      display: flex;
      align-items: center;
      color: #FFFFFF;
      white-space: nowrap;
    }

    .project-link {
      margin-left: 16px;

      & > svg {
        width: 20px;
      }
    }

    .project-categories {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-left: 32px;

      @media (max-width: 767px) {
        width: 100%;
        margin-left: 0;
        margin-top: 32px;
      }

      .project-category {
        font-family: $fontTitle;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #959595;
        padding: 10px 16px;
        background: #292929;
        border-radius: 40px;
        margin: 4px 16px 4px 0;
      }
    }
  }
}
