.hoffman-life-split {
  position: relative;
  width: 100%;
  display: flex;
  align-items: stretch;
  background-color: $white;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 230px;
    transform: translateY(45%);
    background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMzEgMjMyIj48bWFzayBpZD0iYSIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMCIgeT0iMCIgd2lkdGg9IjEzMSIgaGVpZ2h0PSIyMzIiPjxwYXRoIGZpbGw9IiNDNEM0QzQiIGQ9Ik0wIDBoMTMxdjIzMkgweiIvPjwvbWFzaz48ZyBtYXNrPSJ1cmwoI2EpIj48cGF0aCBkPSJNMTE5LjgwNSAxIDY4LjIxOCAyMzFIMS43NDlMNTMuMzM2IDFoNjYuNDY5WiIgc3Ryb2tlPSIjOTU5NTk1IiBzdHJva2Utd2lkdGg9IjIiLz48L2c+PC9zdmc+");
    background-repeat: repeat-x;
    background-size: auto calc(100% + 1px);
    background-position: center center;
    opacity: .5;
    z-index: 2;

    @media (max-width: 1023px) {
      height: 180px;
    }

    @media (max-width: 767px) {
      height: 230px;
    }
  }

  .split-image-container {
    width: 50%;
    position: relative;
    z-index: 3;

    @media (max-width: 767px) {
      width: 100%;
    }

    .split-image {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center 30%;

      @media (max-width: 767px) {
        position: relative;
      }
    }
  }

  .split-content {
    width: 50%;
    padding: 100px 40px 200px 120px;

    @media (max-width: 1023px) {
      padding: 40px 40px 150px;
    }

    @media (max-width: 767px) {
      width: 100%;
      padding: 40px 16px 180px;
    }

    .content-heading {
      font-family: $fontTitle;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 136%;
      color: $colorAccent2;
      margin: 0 0 30px;
    }

    .content-text {
      width: 100%;
      max-width: 435px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 155%;
      color: $colorAccent2;
      margin: 0;
    }
  }
}
