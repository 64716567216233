/*! Site Buttons
---------------------------------------------- */
.primary-btn {
  font-family: $fontTitle;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  display: flex;
  align-items: center;
  text-align: center;
  color: $light;
  margin: 0;
  padding: 12px 30px;
  border: 1px solid $light;
  background-color: transparent;
  transition: all ease-out 0.2s;
  cursor: pointer;
  outline: none;
  appearance: none;
  max-height: 40px;

  &:hover {
    border-color: $colorAccent1;
    background-color: $colorAccent1;
    color: $colorAccent2;
  }

  &.is-darker {
    color: $colorAccent2;
    border-color: $colorAccent2;

    &:hover {
      border-color: $colorAccent2;
      background-color: $colorAccent2;
      color: $colorAccent1;
    }
  }

  &.is-revert {
    color: $colorAccent2;
    border-color: $colorAccent1;
    background-color: $colorAccent1;

    &:hover {
      border-color: $colorAccent1;
      background-color: $colorAccent2;
      color: $colorAccent1;
    }
  }

  &.with-arrow:after {
    content: '';
    width: 12px;
    height: 12px;
    margin-left: 8px;
    transition: transform ease-out 0.2s;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDYgMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMC43MjE0MSAwLjVMNiA2LjQ5OTk4TDAuNzIxNDEgMTIuNUw0LjE5NjI5ZS0wNyAxMS41NzY5TDQuNCA2LjVMMCAxLjQyMzA4TDAuNzIxNDEgMC41WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==");

    &:hover {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDYgMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMC43MjE0MSAwLjVMNiA2LjQ5OTk4TDAuNzIxNDEgMTIuNUw0LjE5NjI5ZS0wNyAxMS41NzY5TDQuNCA2LjVMMCAxLjQyMzA4TDAuNzIxNDEgMC41WiIgZmlsbD0iI0ZGRUMwMCIvPgo8L3N2Zz4K");
      transform: rotate(90deg);
    }

    &.is-opened {
      transform: rotate(-90deg);

      &:hover {
        transform: rotate(-90deg);
      }
    }
  }
}

.secondary-btn {
  position: relative;
  font-family: $fontTitle;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: $colorAccent2;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -8px;
    left: 0;
    transition: width ease-out 0.2s;
    width: 32px;
    height: 1.5px;
    color: $colorAccent2;
    background-color: $colorAccent2;
  }

  &:hover:after {
    width: 100%;
  }

  &.yellow {
    color: $colorAccent1;

    &:after {
      color: $colorAccent1;
      background-color: $colorAccent1;
    }
  }
}
