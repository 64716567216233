.hoffman-life-advantages {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $colorAccent1;

  .advantages-container {
    width: 100%;
    max-width: calc(1110px + (2 * 40px));
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    padding-top: 160px;
    padding-bottom: 140px;

    @media (max-width: 1023px) {
      padding-top: 120px;
      padding-bottom: 60px;
    }

    @media (max-width: 767px) {
      padding: 150px 16px 40px;
    }

    .advantages-line {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 767px) {
        flex-direction: column;

        &:last-child {
          flex-direction: column-reverse;
        }
      }

      &:last-child  {
        margin-top: 40px;
      }

      .content-roadmap {
        display: flex;
        justify-content: center;
        width: calc(50% - 80px);

        @media (max-width: 1023px) {
          width: calc(50% - 20px);
        }

        @media (max-width: 767px) {
          width: 100%;
          margin-bottom: 40px;
        }

        .roadmap-container {
          position: relative;
          width: 460px;
          height: 460px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          @media (max-width: 1170px) {
            width: 390px;
            height: 390px;
          }

          @media (max-width: 1023px) {
            width: 320px;
            height: 320px;
          }

          @media (max-width: 374px) {
            width: 280px;
            height: 280px;
          }

          .roadmap-circle {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;

            .circle-background {
              r: calc((460px - 1.5px) / 2);
              cx: 50%;
              cy: 50%;
              fill: none;
              stroke: $light;
              stroke-width: 1.5px;

              @media (max-width: 1170px) {
                r: calc((390px - 1.5px) / 2);
              }

              @media (max-width: 1023px) {
                r: calc((320px - 1.5px) / 2);
              }

              @media (max-width: 374px) {
                r: calc((280px - 1.5px) / 2);
              }
            }

            .circle-foreground {
              r: calc((460px - 8px) / 2);
              cx: 50%;
              cy: 50%;
              fill: none;
              stroke-width: 8px;
              stroke: $colorAccent2;
              stroke-dasharray: calc((460px - 8px) * 3.14);
              stroke-dashoffset: calc((460px - 8px) * 3.14);
              transform-origin: 50% 50%;
              transform: rotate(-90deg);
              transition: stroke-dashoffset ease-out .2s;

              @media (max-width: 1170px) {
                r: calc((390px - 8px) / 2);
                stroke-dasharray: calc((390px - 8px) * 3.14);
                stroke-dashoffset: calc((390px - 8px) * 3.14);
              }

              @media (max-width: 1023px) {
                r: calc((320px - 8px) / 2);
                stroke-dasharray: calc((320px - 8px) * 3.14);
                stroke-dashoffset: calc((320px - 8px) * 3.14);
              }

              @media (max-width: 374px) {
                r: calc((280px - 8px) / 2);
                stroke-dasharray: calc((280px - 8px) * 3.14);
                stroke-dashoffset: calc((280px - 8px) * 3.14);
              }
            }
          }

          .roadmap-heading {
            width: 100%;
            max-width: 250px;
            font-family: $fontTitle;
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 135%;
            text-align: center;
            color: $colorAccent2;
            margin: 0 0 25px;

            @media (max-width: 1023px) {
              font-size: 27px;
              margin-bottom: 15px;
            }

            @media (max-width: 374px) {
              font-size: 24px;
              margin-bottom: 10px;
            }
          }

          .roadmap-text {
            width: 100%;
            max-width: 280px;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 155%;
            text-align: center;
            color: $grey;
            margin: 0;

            @media (max-width: 1023px) {
              font-size: 14px;
            }

            @media (max-width: 374px) {
              max-width: 240px;
              font-size: 12px;
            }
          }
        }
      }

      .content-gif-container {
        width: calc(50% - 80px);

        @media (max-width: 1023px) {
          width: calc(50% - 20px);
        }

        @media (max-width: 767px) {
          width: 100%;
          margin-bottom: 40px;
        }

        .content-gif {
          width: 100%;
        }
      }

      .column-content {
        width: calc(50% - 80px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media (max-width: 1023px) {
          width: calc(50% - 20px);
        }

        @media (max-width: 767px) {
          width: 100%;
        }

        .content-heading {
          width: 100%;
          max-width: 420px;
          font-family: $fontTitle;
          font-style: normal;
          font-weight: 600;
          font-size: 36px;
          line-height: 135%;
          color: $colorAccent2;
          margin: 0 0 30px;
        }

        .content-text {
          width: 100%;
          max-width: 390px;
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 155%;
          margin: 0;
        }

        .content-subtitle {
          font-family: $fontTitle;
          font-style: normal;
          font-weight: normal;
          font-size: 30px;
          line-height: 135%;
          color: $colorAccent2;
          margin: 40px 0 0;
        }
      }
    }

    .advantages-column {
      width: calc(50% - 35px);
      display: flex;
      flex-direction: column;
    }
  }
}
