.hoffman-life-hero {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;

  .hero-container {
    width: 100%;
    max-width: calc(1110px + (2 * 40px));
    padding: 0 40px;
    display: flex;
    padding-top: 160px;
    padding-bottom: 120px;

    @media (max-width: 1023px) {
      padding-top: 120px;
      padding-bottom: 60px;
    }

    @media (max-width: 767px) {
      padding: 40px 16px;
    }

    .hero-text {
      font-family: $fontTitle;
      font-style: normal;
      font-weight: 600;
      font-size: 96px;
      line-height: 110%;
      min-height: 211.2px;
      color: $colorAccent1;
      margin: 0;

      @media (max-width: 1023px) {
        font-size: 64px;
        min-height: 140.8px;
      }

      @media (max-width: 767px) {
        font-size: 34px;
        line-height: 135%;
        min-height: 91.8px;
      }
    }
  }

  .hero-logo {
    position: absolute;
    top: 30px;
    left: 64px;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1023px) {
      top: 10px;
      left: 40px;
      width: 80px;
      height: 80px;
    }

    @media (max-width: 767px) {
      display: none;
    }

    .logo-hoffman {
      height: 45px;
      margin-top: -7px;

      @media (max-width: 1023px) {
        height: 25px;
        margin-top: -3px;
      }
    }

    .rotate-img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      animation: spin 16s linear infinite;
    }
  }
}
