.hoffman-life-teams {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $colorAccent2;

  .teams-container {
    width: 100%;
    max-width: calc(1110px + (2 * 40px));
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 100px;

    @media (max-width: 1023px) {
      padding-top: 80px;
      padding-bottom: 60px;
    }

    @media (max-width: 767px) {
      padding: 40px 16px;
    }

    .teams-line {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      &:last-child {
        margin-top: 90px;

        @media (max-width: 767px) {
          margin-top: 60px;
        }
      }

      @media (max-width: 1023px) {
        flex-direction: column-reverse;
        align-items: flex-start;
      }

      .teams-list {
        display: flex;
        margin-bottom: 50px;

        @media (max-width: 1023px) {
          margin-top: 170px;
          margin-bottom: 0;
        }

        @media (max-width: 767px) {
          flex-direction: column;
          width: 100%;
          margin-top: 0;
        }

        .team-item {
          width: 200px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-right: 35px;

          @media (max-width: 767px) {
            width: 100%;
            margin-bottom: 20px;
            margin-right: 0;
            flex-direction: row;
            align-items: center;
          }

          &:last-child {
            margin-right: 0;

            @media (max-width: 1023px) {
              margin-top: -140px;
            }

            @media (max-width: 767px) {
              margin-top: 0;
              margin-bottom: 0;
            }
          }

          &:nth-child(1) {
            margin-top: -140px;

            @media (max-width: 1023px) {
              margin-top: 0;
            }
          }

          &:nth-child(2) {
            margin-top: -70px;

            @media (max-width: 767px) {
              margin-top: 0;
            }

            .team-logo-container {
              background-color: $departmentGreen;
            }
          }

          &:nth-child(3) {
            .team-logo-container {
              background-color: $departmentBlue;
            }
          }

          .team-logo-container {
            width: 100%;
            height: 350px;
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 16px;

            @media (max-width: 767px) {
              width: 70px;
              height: 130px;
              margin-right: 16px;
              flex-shrink: 0;
              margin-bottom: 0;
            }

            &.pink {
              background-color: $departmentPink;
            }

            &.green {
              background-color: $departmentGreen;
            }

            &.blue {
              background-color: $departmentBlue;
            }

            svg {
              height: 100px;

              @media (max-width: 767px) {
                height: 36px;
              }
            }
          }

          .team-info {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @media (max-width: 767px) {
              flex: 1;
            }

            .team-name {
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 155%;
              color: $white;
              margin: 0 0 4px;
            }

            .team-description {
              font-style: normal;
              font-weight: normal;
              font-size: 18px;
              line-height: 155%;
              color: $light;
              margin: 0;
            }
          }
        }
      }

      .teams-summary {
        width: 100%;
        max-width: 256px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 40px;

        @media (max-width: 1023px) {
          margin-left: 0;
          max-width: 500px;
        }

        @media (max-width: 767px) {
          margin-bottom: 40px;
        }

        .summary-heading {
          font-family: $fontTitle;
          font-style: normal;
          font-weight: bold;
          font-size: 36px;
          line-height: 135%;
          color: $white;
          margin: 0 0 16px;

          @media (max-width: 767px) {
            margin-bottom: 20px;
          }
        }

        .summary-subtitle {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 155%;
          color: $white;
          margin: 0 0 24px;

          @media (max-width: 767px) {
            margin-bottom: 20px;
          }
        }

        .summary-text {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 155%;
          color: $light;
          margin: 0;
        }
      }

      .come-container {
        width: 100%;
        max-width: 630px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .come-heading {
          font-family: $fontTitle;
          font-style: normal;
          font-weight: 600;
          font-size: 72px;
          line-height: 120%;
          color: $colorAccent1;
          margin: 0 0 40px;

          @media (max-width: 767px) {
            font-size: 40px;
            line-height: 135%;
            margin-bottom: 20px;
          }
        }

        .come-text {
          font-style: normal;
          font-weight: 300;
          font-size: 24px;
          line-height: 140%;
          color: $white;
          margin: 0 0 40px;

          @media (max-width: 767px) {
            margin-bottom: 80px;
          }
        }
      }
    }
  }
}
