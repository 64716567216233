.block-advantages {

  background: linear-gradient(to bottom, white 15%, $colorAccent2 15%);
  padding-top: 152px;
  padding-bottom: 164px;

  @media (max-width: $md-break) {
    background: linear-gradient(to bottom, white 10%, $colorAccent2 10%);
  }

  &.no-slides {
    background: linear-gradient(to bottom, $colorAccent2 0%, $colorAccent2 100%);

    @media (max-width: $md-break) {
      background: linear-gradient(to bottom, $colorAccent2 0%, $colorAccent2 100%);
    }
  }

  .advantages-title {
    color: $colorAccent1;
    max-width: 512px;
    margin-bottom: 108px;

    @media (max-width: $md-break) {

      font-size: 72px;
      font-style: normal;
      font-weight: 600;
      line-height: 86px;
      letter-spacing: 0em;
      text-align: left;

      margin-bottom: 119px;

    }

    @media (max-width: $sm-break) {

      max-width: 277px;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 55px;
      letter-spacing: 0em;
      text-align: left;

      margin-bottom: 87px;


    }
  }

  .slider-advantages-wrapper {
    display: block;
    position: relative;
    margin-bottom: 170px;

    @media (max-width: $sm-break) {
      margin-bottom: 40px;
    }

    .slider-card {
      width: 100%;
      max-width: calc(1120px - 40px);
      margin-right: 25px;
      margin-left: 25px;

      @media (max-width: $md-break) {
        max-width: calc(100% - 80px);
        margin-left: 0px;
      }

      @media (max-width: $sm-break) {
        max-width: calc(100% - 40px);
      }

      img {
        width: 100%;

        &.desktop {
          display: block;
        }

        &.tablet {
          display: none;
        }

        &.mobile {
          display: none;
        }

        @media (max-width: $md-break) {
          &.desktop {
            display: none;
          }

          &.tablet {
            display: block;
          }

          &.mobile {
            display: none;
          }
        }

        @media (max-width: $sm-break) {
          &.desktop {
            display: none;
          }

          &.tablet {
            display: none;
          }

          &.mobile {
            display: block;
          }
        }
      }
    }
  }

  .advantages-wrapper {
    display: flex;
    flex-wrap: wrap;

    .advantage-wrapper {
      width: calc(100% / 3 - ((89px / 3) *2));
      margin-right: 89px;
      margin-bottom: 85px;

      &:nth-child(3n+3) {
        margin-right: 0;
      }

      @media (max-width: $xxxxl-break) {
        width: calc(100% / 3 - ((61px / 3) *2));
        margin-right: 61px;
        margin-bottom: 85px;
      }

      @media (max-width: $xxl-break) {
        width: calc(100% / 3 - ((41px / 3) *2));
        margin-right: 41px;
        margin-bottom: 85px;
      }

      @media (max-width: $xl-break) {
        width: calc(100% / 2 - 33px);
        margin-right: 66px;

        &:nth-child(3n+3) {
          margin-right: 66px;
        }

        &:nth-child(2n+2) {
          margin-right: 0px;
        }
      }

      @media (max-width: $lg-break) {
        width: 100%;
        margin-right: 0px;

        &:nth-child(3n+3) {
          margin-right: 0px;
        }

        &:nth-child(2n+2) {
          margin-right: 0px;
        }
      }

      .advantage-heading {
        position: relative;
        display: flex;
        justify-content: space-between;
        border-bottom: 1.5px solid #FFFFFF;
        padding-bottom: 30px;
        margin-bottom: 30px;

        .advantage-title {
          color: $white;
          font-family: $fontTitle;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: 41px;
          letter-spacing: 0em;
          max-width: 100%;

          @media (max-width: $sm-break) {
            max-width: 234px;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 41px;
            letter-spacing: 0em;
            text-align: left;
          }
        }

        img {
          display: block;
          width: 35px;
          height: 35px;
          margin-top: 3px;
        }
      }



      .advantage-text {
        color: $white;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 28px;
        letter-spacing: 0em;

        p {
          margin: 0;
        }
      }
    }
  }
}
