.block-become {
  background-color: $colorAccent1;
  padding-top: 120px;
  padding-bottom: 156.5px;


  @media (max-width: $md-break) {
    padding-top: 98px;
    padding-bottom: 120.5px;
  }

  @media (max-width: $sm-break) {
    padding-top: 88px;
    padding-bottom: 89.5px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 0;
    padding-bottom: 110px;
    border-bottom: 1px solid $dark;

    &.become-wrapper-content {
      align-items: flex-start;

      .become-wrapper {
        padding-top: 16px;
      }
    }

    @media (max-width: $md-break) {
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 88px;
    }

    @media (max-width: $sm-break) {
      padding-top: 88px;
      padding-bottom: 89.5px;
      padding-bottom: 74px;
    }

    .become-title {
      display: block;
      width: 50%;
      margin-top: 0;
      margin-bottom: 44px;

      @media (max-width: $md-break) {
        width: 100%;
        max-width: 100%;

        font-size: 72px;
        font-style: normal;
        font-weight: 600;
        line-height: 86px;
        letter-spacing: 0em;
        text-align: left;
      }

      @media (max-width: $sm-break) {
        width: 100%;
        max-width: 199px;

        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 55px;
        letter-spacing: 0em;
        text-align: left;

      }

    }

    .form-wrapper {
      position: relative;

      &.is-active {
        display: block;
        min-height: 500px;
        width: 100%;
      }
    }

    .become-wrapper {
      width: 50%;
      max-width: 544px;

      @media (max-width: $md-break) {
        width: 100%;
        max-width: 761px;
      }

      @media (max-width: $sm-break) {
        width: 100%;
        max-width: 100%;

      }

      .become-text {
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 34px;
        letter-spacing: 0em;
        margin: 0;

        @media (max-width: $md-break) {
          width: 100%;
          max-width: 100%;

          font-size: 24px;
          font-style: normal;
          font-weight: 300;
          line-height: 34px;
          letter-spacing: 0em;
          text-align: left;
        }

        @media (max-width: $sm-break) {
          width: 100%;

          font-size: 24px;
          font-style: normal;
          font-weight: 300;
          line-height: 34px;
          letter-spacing: 0em;
          text-align: left;
        }
      }

      .become-btn {
        display: flex;
        align-items: center;
        margin-top: 32px;
        margin-bottom: 0;

        span {
          display: block;
          width: 165px;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 19px;
          letter-spacing: 0em;
        }

        svg {
          margin-right: 11px;
        }
      }
    }
  }

}
