.single-project {
  width: 100%;
  display: flex;
  flex-direction: column;

  .project-image {
    width: 100%;
  }

  .project-infos {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 45px;
    flex-wrap: wrap;

    .project-name {
      font-family: $fontTitle;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      display: flex;
      align-items: center;
      color: #FFFFFF;
      white-space: nowrap;
    }

    .project-link {
      margin-left: 16px;

      & > svg {
        width: 20px;
      }
    }

    .project-categories {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 10px;

      .project-category {
        font-family: $fontTitle;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #959595;
        padding: 10px 16px;
        background: #292929;
        border-radius: 40px;
        margin: 4px 16px 4px 0;
      }
    }
  }
}
