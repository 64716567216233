.slider-accelerate {
  background: white;
  padding-top: 196px;
  position: relative;

  @media (max-width: $md-break) {
    padding-top: 155px;
    padding-bottom: 213px;
  }

  @media (max-width: $sm-break) {
    padding-top: 130px;
    padding-bottom: 149px;
  }

  .slider-content {
    position: relative;

    .content-container {
      @media (max-width: $sm-break) {
        padding: 0;
      }
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .slider-title {
    margin-bottom: 100px;

    @media (max-width: $md-break) {
      margin-bottom: 74px;
    }

    @media (max-width: $sm-break) {
      padding-left: 20px;
      padding-right: 20px;
    }

    span {
      display: block;
      position: relative;

      @media (max-width: $sm-break) {
        max-width: 218px;
        align-items: center;
      }
    }


    sup {
      display: block;
      margin-top: 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 27px;
      line-height: 37px;
      position: absolute;
      top: -10px;
      right: -30px;

      @media (max-width: $md-break) {
        font-size: 13.5px;
        line-height: 18px;
        right: -10px;
      }

      @media (max-width: $sm-break) {
        bottom: 30px;
        right: 0;
        top: auto;
      }
    }
  }

  .text-content {
    width: 100%;

    @media (max-width: $sm-break) {
      padding-left: 20px;
      padding-right: 20px;
    }

    .slider-text {
      display: block;
      max-width: 328px;
      border-top: 1.5px solid black;
      border-bottom: 1.5px solid black;
      padding-top: 56px;
      padding-bottom: 56px;

      @media (max-width: $lg-break) {
        max-width: 250px;
      }

      @media (max-width: 767px) {
        max-width: 100%;
        margin-bottom: 82px;
      }
    }
  }

  .slider-wrapper {
    display: block;
    width: 50%;
    position: absolute;
    bottom: 0;
    right: 0;

    @media (max-width: $lg-break) {
      width: 60%;
    }

    @media (max-width: 767px) {
      position: relative;
      bottom: auto;
      right: auto;
      width: 100%;
      padding-left: 20px;
    }

    .slider-card {
      display: block;
      width: 65%;
      position: relative;
      padding-left: 130px;
      padding-top: 40px;



      @media (max-width: 767px) {
        padding-left: 0;
        margin-right: 45px;
        width: 100%;
        max-width: 229px;
      }


      &.is-selected {
        .img-number {
          display: none;
          opacity: 0;
        }

        .img-number-full {
          display: block;
          opacity: 1;

          @media (max-width: 767px) {
            position: relative;
            top: auto;
            left: auto;
          }
        }
      }

      .img-number {
        display: block;
        position: absolute;
        opacity: 1;
        z-index: 1;
        top: 0;
        left: 0;

        @media (max-width: 767px) {
          position: relative;
          top: auto;
          left: auto;
        }
      }

      .img-number-full {
        display: none;
        position: absolute;
        opacity: 0;
        z-index: 1;
        top: 0;
        left: 0;

        @media (max-width: $sm-break) {
          position: relative;
          top: auto;
          left: auto;
        }
      }

      .card-title {
        position: relative;
        margin-bottom: 30px;
        z-index: 2;

        @media (max-width: 767px) {
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: 41px;
          letter-spacing: 0em;
          margin-top: 20px;

        }
      }

      .card-text {
        position: relative;
        color: #959595;
        margin-bottom: 30px;
        z-index: 2;
      }

      .card-btn {
        position: relative;
        z-index: 2;
      }
    }
  }
}

.slider-projects {
  background: linear-gradient(to bottom, white 55%, $colorAccent2 55%);

  padding-top: 149px;
  padding-bottom: 44px;

  @media (max-width: $md-break) {
    padding-top: 213px;
    padding-bottom: 0px;
  }

  @media (max-width: $sm-break) {
    padding-top: 38px;
    padding-bottom: 0px;
  }

  .content {
    @media (max-width: $lg-break) {
      padding-bottom: 20px;
    }
  }

  .flickity-prev-next-button{
    top: -100px;
    background: none;

    &.previous {
      left: auto;
      right: 278px;
    }

    &.next {
      right: 163px;
    }

    @media (max-width: $xl-break) {
      top: -31px;

      &.previous {
        left: auto;
        right: 42px;
      }

      &.next {
        right: 21px;
      }
    }

    @media (max-width: $md-break) {
      top: -31px;

      &.previous {
        left: auto;
        right: 42px;
      }

      &.next {
        right: 21px;
      }
    }

    @media (max-width: $sm-break) {
      top: -100px;

      &.previous {
        left: auto;
        right: 278px;
      }

      &.next {
        right: 163px;
      }
    }
  }

  .slider-content {
    background: url(/dist/img/slash-separator.svg) top center / contain no-repeat;
    position: relative;

    @media (max-width: $md-break) {
      background: url(/dist/img/slash-separator.svg) top center / 195% no-repeat;
    }

    @media (max-width: $md-break) {
      background: url(/dist/img/slash-separator.svg) top center / 345% no-repeat;
    }

    .slider-title {
      padding-top: 100px;
      padding-bottom: 90px;

      @media (max-width: $md-break) {
        padding-top: 110px;
        padding-bottom: 70px;

        font-size: 72px;
        font-style: normal;
        font-weight: 600;
        line-height: 86px;
        letter-spacing: 0em;
        text-align: left;

      }

      @media (max-width: $sm-break) {
        padding-top: 110px;
        padding-bottom: 100px;

        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 55px;
        letter-spacing: 0em;
        text-align: left;
      }
    }

    .all-projects-btn {
      position: absolute;
      bottom: 20px;
      right: 166px;
      color: $colorAccent1;
      z-index: 10;

      &:after {
        color: $colorAccent1;
        background-color: $colorAccent1;
      }

      @media (max-width: $md-break) {
        position: relative;
        bottom: auto;
        right: auto;
        top: auto;
        left: auto;
        color: $dark;

        &:after {
          background-color: $dark;
        }
      }
    }

    .slider-projects-wrapper {
      width: 100%;

      .slider-card {
        width: 100%;
        max-width: calc(1120px - 40px);
        margin-right: 25px;
        margin-left: 25px;


        @media (max-width: $xl-break) {
          max-width: calc(100% - 40px);
          margin-left: 0px;
        }

        @media (max-width: $sm-break) {
          max-width: calc(100% - 40px);
        }

        .project-infos {
          transform: translateY(50px);
          opacity: 0;
          transition: all ease-out .2s;
        }

        &.is-selected {
          .project-infos {
            opacity: 1;
            transform: translate(0);
          }
        }
      }
    }
  }
}
