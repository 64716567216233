.hoffman-life-gallery {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $white;

  .gallery-container {
    width: 100%;
    max-width: calc(1110px + (2 * 40px));
    padding: 100px 40px 80px;
    display: flex;
    flex-direction: column;

    @media (max-width: 1023px) {
      padding: 80px 40px;
    }

    @media (max-width: 767px) {
      padding: 30px 16px;
    }

    .gallery-heading {
      font-family: $fontTitle;
      font-style: normal;
      font-weight: 600;
      font-size: 72px;
      line-height: 120%;
      color: $colorAccent2;
      margin: 0 0 115px;

      @media (max-width: 1250px) {
        font-size: 60px;
      }

      @media (max-width: 1023px) {
        font-size: 45px;
        margin-bottom: 60px;
      }

      @media (max-width: 767px) {
        font-size: 22px;
        margin-bottom: 40px;
      }

      &.is-active {
        .line {
          transform: translateY(0);
          opacity: 1;

          & > em:before {
            width: 100%;
          }
        }
      }

      .line {
        transform: translateY(100px);
        opacity: 0;
        transition: all ease-out .6s;

        &:nth-child(2) {transition-delay: .3s;}
        &:nth-child(3) {transition-delay: .4s;}
        &:nth-child(4) {transition-delay: .5s;}
        &:nth-child(5) {transition-delay: .6s;}

        & > em {
          position: relative;
          font-style: normal;

          em {
            position: relative;
            font-style: normal;
          }

          &:before {
            position: absolute;
            content: '';
            background-color: $colorAccent1;
            bottom: 0;
            left: 0;
            width: 0;
            height: 65%;
            transition: width ease-out .4s;
            transition-delay: 1s;
          }
        }
      }
    }

    .gallery-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .gallery-column {
        width: calc((100% - 50px) / 3);
        display: flex;
        flex-direction: column;

        @media (max-width: 767px) {
          width: calc((100% - 20px) / 2);

          &:nth-child(3) {
            display: none
          }
        }

        .gallery-item {
          position: relative;
          width: 100%;
          padding-top: 120%;
          overflow: hidden;

          &:nth-child(2) {
            margin-top: 25px;
            padding-top: 65%;

            @media (max-width: 767px) {
              margin-top: 20px;
            }
          }

          .item-gallery-container {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            animation-name: rotate-for-2;
            animation-iteration-count: infinite;
            animation-duration: 8s;

            &.for-3 {
              animation-name: rotate-for-3;
              animation-duration: 12s;
            }

            &.for-4 {
              animation-name: rotate-for-4;
              animation-duration: 16s;
            }

            &.for-5 {
              animation-name: rotate-for-5;
              animation-duration: 20s;
            }

            &:nth-child(2) {
              animation-delay: 4s;
            }

            &:nth-child(3) {
              animation-delay: 8s;
            }

            &:nth-child(4) {
              animation-delay: 12s;
            }

            &:nth-child(5) {
              animation-delay: 16s;
            }

            @keyframes rotate-for-2 {
              0% {
                opacity: 1;
                height: 100%;
                transform: scale(1);
              }
              50% {
                transform: scale(1.3);
                height: 100%;
              }
              55% {
                opacity: 1;
              }
              60% {
                height: 0;
                z-index: 2;
                opacity: 0;
              }
              61% {
                z-index: 1;
              }
              90% {
                height: 0;
              }
            }

            @keyframes rotate-for-3 {
              0% {
                opacity: 1;
                height: 100%;
                transform: scale(1);
              }
              33% {
                transform: scale(1.3);
                height: 100%;
              }
              35% {
                opacity: 1;
              }
              40% {
                height: 0;
                z-index: 2;
                opacity: 0;
              }
              41% {
                z-index: 1;
              }
              90% {
                height: 0;
              }
            }

            @keyframes rotate-for-4 {
              0% {
                opacity: 1;
                height: 100%;
                transform: scale(1);
              }
              25% {
                transform: scale(1.3);
                height: 100%;
              }
              27% {
                opacity: 1;
              }
              30% {
                height: 0;
                z-index: 2;
                opacity: 0;
              }
              31% {
                z-index: 1;
              }
              90% {
                height: 0;
              }
            }

            @keyframes rotate-for-5 {
              0% {
                opacity: 1;
                height: 100%;
                transform: scale(1);
              }
              20% {
                transform: scale(1.3);
                height: 100%;
              }
              22% {
                opacity: 1;
              }
              24% {
                height: 0;
                z-index: 2;
                opacity: 0;
              }
              25% {
                z-index: 1;
              }
              90% {
                height: 0;
              }
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: top center;
            }
          }
        }

        &:nth-child(2) {
          flex-direction: column-reverse;

          .gallery-item:nth-child(2) {
            margin-top: 0;
            margin-bottom: 25px;

            @media (max-width: 767px) {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}
