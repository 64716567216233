.block-image {
  background: linear-gradient(to bottom, $colorAccent1 20%, white 20%);

  &.career-page {
    background: linear-gradient(to bottom, $colorAccent2 20%, white 20%);

    .img-wrapper {
      background: url(/dist/img/slash-separator-grey.svg) center center no-repeat;
      background-size: contain;
    }
  }

  .img-wrapper {
    background: url(/dist/img/slash-separator.svg) center center no-repeat;
    background-size: contain;


    .dynamique-image {
      display: block;
      min-width: 1120px;
      width: 60%;
      margin: 0 auto;

      @media (max-width: $md-break) {
        padding: 0 20px;
        min-width: 100%;
      }

      &.desktop-version {
        display: block;

        @media (max-width: $md-break) {
          display: none;
        }

      }

      &.tablet-version {
        display: none;

        @media (max-width: $md-break) {
          display: block;
        }

        @media (max-width: $sm-break) {
          display: none;
        }
      }

      &.mobile-version {
        display: none;

        @media (max-width: $sm-break) {
          display: block;
        }
      }
    }
  }
}
