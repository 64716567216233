.basic-form {
  display: none;
  position: relative;

  flex-wrap: wrap;
  position: relative;
  z-index: 5;
  width: 100%;
  height: 0;
  opacity: 0;
  margin-top: 85px;
  transition: all .35s ease-in-out;

  &.is-active {
    display: flex;
    opacity: 1;
    height: 100%;
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family: $fontTitle;
    font-size: 36px;
    line-height: 49px;
    color: rgba($colorAccent2, .5);
    margin: 0;
    font-weight: 600;

    @media (max-width: $md-break) {
      font-size: 24px;
      line-height: 33px;
    }

    @media (max-width: $sm-break) {
      font-size: 14px;
      line-height: 1;
    }
  }

  ::-moz-placeholder { /* Firefox 19+ */
    font-family: $fontTitle;
    font-size: 36px;
    line-height: 49px;
    color: rgba($colorAccent2, .5);
    margin: 0;
    font-weight: 600;

    @media (max-width: $md-break) {
      font-size: 24px;
      line-height: 33px;
    }

    @media (max-width: $sm-break) {
      font-size: 14px;
      line-height: 1;
    }
  }

  :-ms-input-placeholder { /* IE 10+ */
    font-family: $fontTitle;
    font-size: 36px;
    line-height: 49px;
    color: rgba($colorAccent2, .5);
    margin: 0;
    font-weight: 600;

    @media (max-width: $md-break) {
      font-size: 24px;
      line-height: 33px;
    }

    @media (max-width: $sm-break) {
      font-size: 14px;
      line-height: 1;
    }
  }

  :-moz-placeholder { /* Firefox 18- */
    font-family: $fontTitle;
    font-size: 36px;
    line-height: 49px;
    color: rgba($colorAccent2, .5);
    margin: 0;
    font-weight: 600;

    @media (max-width: $md-break) {
      font-size: 24px;
      line-height: 33px;
    }

    @media (max-width: $sm-break) {
      font-size: 14px;
      line-height: 1;
    }
  }

  .form-message {
    margin-bottom: 70px;

    a {
      display: inline-block;
    }
  }

  .input-wrapper {
    position: relative;
    width: 100%;

    &.half-input {
      width: calc(100% / 2 - 16px);
      margin-right: 31px;

      &:nth-child(odd) {
        margin-right: 0;
      }

      @media (max-width: $lg-break) {
        width: 100%;
        margin-right: 0;
      }
    }

    &.for-textarea {
      &:before {
        transform: translate3d(0, 2.25rem, 0);
      }
    }

    input,
    textarea {
      width: 100%;
      margin: 1rem 0 1rem 0;
      padding-left: 1.5rem;
      background: transparent;
      border: 1px solid rgba(21,21,21 , 0.5);
      outline: none;
      resize: none;

      font-family: $fontTitle;
      color: rgba($colorAccent2, 1);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      min-height: 55px;

      @media (max-width: $md-break) {
        font-size: 16px;
        line-height: 33px;
      }

      &:not(:placeholder-shown), &:focus {
        border: 1px solid rgba(21,21,21 , 1);
        & + label {

          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: left;

          top: -10px;
          transform: translateY(0);
          color: $dark;
        }
      }
    }

    label {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 0;
      z-index: -1;
      color: rgba($colorAccent2, 0.5);
      padding-left: 1.5rem;
      background: transparent;
      border: none;


      font-family: $fontText;

      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;

      opacity: 1;
      pointer-events: none;
      transition: all ease-out .2s;


      @media (max-width: $md-break) {
        font-size: 24px;
        line-height: 33px;
      }

      @media (max-width: $sm-break) {
        font-size: 14px;
        line-height: 1;
      }
    }

    textarea {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 2.5;
      letter-spacing: 0em;
    }

    &.for-file {
      max-width: 400px;
      max-height: 80px;
      overflow: hidden;

      input {
        border: none;
        padding-left: 0;


        &::-webkit-file-upload-button {
          color: $dark;
          display: inline-block;
          background: transparent;
          border: 1px solid #151515;
          padding: 7px 15px;
          white-space: nowrap;
          cursor: pointer;
          margin-right: 20px;

          font-family: $fontTitle;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: center;

        }

        &:not(:placeholder-shown), &:focus {
          & + label {
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: 0em;
            color: $light;

            top: -10px;
            transform: translateY(0);
          }
        }
      }

      label {
        position: relative;
        top: auto;
        left: auto;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0em;
        color: $light;
        padding-left: 0;
        margin-bottom: 21px;
      }
    }
  }

  .form-btn {
    border-color: $dark;
    color: $dark;
    margin-right: 0;
    margin-left: auto;
    max-height: 50px;
    align-self: flex-end;

    @media (max-width: $md-break) {
      margin-right: auto;
      margin-left: 0;
      margin-top: 32px;
    }

    &.floating-btn {
      position: absolute;
      bottom: 0;
      right: 0;

      @media (max-width: $md-break) {
        position: relative;
        bottom: auto;
        right: auto;
      }
    }

    &:hover {
      background-color: $dark;
      color: $colorAccent1;
    }
  }
}
.thank-you-partner {
  position: absolute;
  top: -100%;
  left: -100%;
  z-index: 9999;
  opacity: 0;
  transition: opacity .35s ease-in-out;

  &.is-active {
    opacity: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  h2 {
    font-family: $fontTitle;
    font-size: 72px;
    font-style: normal;
    font-weight: 600;
    line-height: 86px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 0;
    margin-bottom: 26px;
  }

  p {
    font-family: $fontTitle;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 49px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;

  }
}
