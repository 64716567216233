.projects-list-container {
  padding-bottom: 60px;

  .projects-filters {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    align-items: center;
    margin-bottom: 50px;

    &::-webkit-scrollbar {
      display: none;
    }

    .project-filter {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 155%;
      color: rgba(#FFFFFF, .5);
      margin-right: 16px;
      white-space: nowrap;
      transition: color ease-out .2s;

      &:after {
        content: '//';
        color: $colorAccent1;
        margin-left: 16px;
      }

      &:last-child {
        margin-right: 0;

        &:after {
          display: none;
        }
      }

      &:hover {
        color: #FFF;
      }

      &.is-active {
        color: #FFFFFF;
      }
    }
  }

  .projects-featured-list {
    width: 100%;
    display: flex;
    flex-direction: column;

    .featured-project {
      margin-bottom: 90px;
    }
  }

  .projects-single-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .single-project {
      width: calc((100% - 64px) / 3);
      margin-right: 32px;
      margin-bottom: 80px;

      &:nth-child(3n-3) {
        margin-right: 0;
      }

      @media (max-width: 1023px) {
        width: calc(50% - 10px);
        margin-right: 20px;

        &:nth-child(3n-3) {
          margin-right: 20px;
        }

        &:nth-child(2n-2) {
          margin-right: 0;
        }
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}
