body {
  overflow-x: hidden;
}

#page-container{
  transition: all .3s ease-in-out;

  &.is-active {
    &::before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: rgba(0,0,0,.65);
      opacity: 0;
    }

    @media all and (max-width: 1140px) {
      transform: translateX(-400px);

      &::before {
        z-index: 15;
        opacity: 1;
      }
    }

    @media all and (max-width: 599px) {
      transform: translateX(-85%);
    }
  }
}



.navigation-mobile {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100000000;
  transform: translateX(514px);
  transition: all 0.3s ease-in-out;
  background-color: $colorAccent1;

  @media all and (max-width: 1140px) {
    width: 514px;
    height: 100vh;
  }

  @media all and (max-width: 599px) {
    width: 85%;
  }

  &.is-active {
    @media all and (max-width: 1140px) {
      transform: translateX(0);
    }
  }

  &.active {
    @media all and (max-width: 1140px) {
      width: 400px;
      display: flex;
    }

    @media all and (max-width: 599px) {
      width: 85%;
    }
  }

  .navigation-header {
    display: flex;
    align-items: center;
    padding: 20px 30px;

    .logo {
      height: 40px;
    }
  }

  .navigation-close {
    flex-grow: 1;
    text-align: right;
    font-size: 28px;
  }

  .navigation-link {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 40px 0;
    margin-left: 26px;
    margin-right: 26px;
    border-bottom: 1px solid $colorAccent2;

    @media (max-width: 767px) {
      margin-left: 29px;
      margin-right: 14px;
    }

    a {
      font-family: Manrope;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 135%;
      letter-spacing: 0em;
      text-align: right;
      color: $colorAccent2;
      margin-bottom: 15px;

      @media (max-width: 767px) {
        font-size: 24px;
      }
    }

    .mobile-lang-switcher {
      font-family: $fontTitle;
      font-size: 24px;
      line-height: 33px;
      color: $colorAccent2;
      margin: 0;

        font-weight: 600;

    }

    .mobile-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 5px 7px;
      background: #cfbc10;
      border-radius: 3px;
      border: 1px solid #151515;
      max-width: 107px;

      &:after {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: $dark;
        border-radius: 50%;
        margin-left: 7px;
      }


      p {
        font-family: $fontText;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #151515;
        margin: 0;
        padding: 0;
      }
    }
  }

  .navigation-sublink {
    display: flex;
    flex-direction: column;
    padding: 0px 30px 15px;
    border-bottom: 2px solid $white;

    a {
      margin-bottom: 15px;
      color: $colorAccent2;
      font-size: 16px;
      text-transform: uppercase;

      &.site-btn {
        color: white;
        background-color: $colorAccent1;
      }
    }
  }

  .navigation-footer {
    display: flex;
    align-items: center;
    padding: 20px 30px;

    a {
      font-size: 13px;
      padding-left: 20px;

      i {
        position: relative;
        top: 1px;
        font-size: 16px;
      }
    }
  }

  .navigation-footer-social {
    flex-grow: 1;

    a {
      padding: 0;
    }

    img {
      padding-right: 20px;
      height: 14px;
      transition: all .3s ease-in-out;
    }
  }

  .subnav-mobile {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-bottom: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;

    &.active {
      max-height: inherit;
      opacity: 1;
      padding-bottom: 20px;
    }

    a {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  .mobile-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
    padding-right: 30px;
    padding-left: 25px;

    @media (max-width: 599px) {
      flex-direction: column-reverse;
      align-items: flex-end;
      padding-left: 16px;
    }

    .mobile-socials {
      justify-content: flex-end;

      a {
        margin-right: 9px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .sub-navigation-mobile {

      @media (max-width: 599px) {
        margin-top: 17px;
      }

      a {
        font-family: $fontText;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 155%;
        /* or 28px */
        color: #000000;
      }
    }
  }


}
