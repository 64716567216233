/**
 * pages/homepage.pcss
 *
 * Styles for the Home page.
 *
 */

/*! Page Header and Navigation
---------------------------------------------- */
.header {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #151515;
  z-index: 9900;
  width: 100%;
  padding: 30px 33px;
  transition: all ease-out .2s;

  @media (max-width: 980px) {
    padding: 16px;
  }

  .header-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .header-logo-container {
    display: flex;

    &.on-scroll {
      display: none;
    }

    .header-logo {
      max-width: 208px;
      height: 46px;

      @media (max-width: 980px) {
        height: 40px;
      }
    }
  }

  &.is-active {
    background-color: $white;
    padding: 13px 33px;
    //border-bottom: 1px solid #A5B2C6;

    @media (max-width: 980px) {
      padding: 16px;

      border-bottom: none;
    }

    .header-logo-container {
      display: none;

      &.on-scroll {
        display: flex;

        .header-logo {

          max-width: 208px;

          height: 32px;

          @media (max-width: 1024px) {
            height: 42px;
          }

          @media (max-width: 767px) {
            height: 38px;
          }
        }
      }
    }



    .header-nav {
      .header-nav-link {

        &:hover {
          color: $dark;
        }

        &.is-active {
          color: $dark;

          &:after {
            content: '';
            position: absolute;
            width: 4px;
            height: 4px;
            border-radius: 4px;
            background-color: $colorAccent1;
            left: 50%;
            bottom: -10px;
            transform: translateX(-50%);
          }
        }

        @media (max-width: 980px) {
          display: none;
        }
      }
      .header-burger-menu {
        &.on-scroll {
          display: none;

          @media (max-width: 980px) {
            display: flex;
          }
        }
      }
    }
  }

  &.hide-nav-bar {
    top: -200px;

    transition: top .35s ease-in-out;
  }





  .header-nav {
    display: flex;
    align-items: center;

    .primary-btn {
      @media (max-width: 980px) {
        display: none;
      }
    }

    .header-nav-link {
      position: relative;
      font-family: $fontTitle;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: $light;
      margin-right: 40px;
      transition: color ease-out 0.2s;

      &:hover {
        color: $white;
      }

      &.is-active {
        color: $white;

        &:after {
          content: '';
          position: absolute;
          width: 4px;
          height: 4px;
          border-radius: 4px;
          background-color: $colorAccent1;
          left: 50%;
          bottom: -10px;
          transform: translateX(-50%);
        }
      }

      @media (max-width: 980px) {
        display: none;
      }
    }

    .header-burger-menu {
      display: none;
      cursor: pointer;
      height: 40px;
      align-items: center;

      &.on-scroll {
        display: none;
      }

      @media (max-width: 980px) {
        display: flex;
      }
    }
  }
}

#content-container {
  padding-top: 106px;

  &.light {
    padding-top: 0;
  }
}

/*! Footer
---------------------------------------------- */
.footer {
  max-width: 1120px;
  margin: 0 auto;
  padding: 50px 20px;

  .footer-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    border-bottom: 1.5px solid $light;


    .header-logo-container {
      img {
        display: block;
      }
    }

    .social-links {
      .social-media-link {
        max-width: 35px;
        max-height: 35px;

        &.facebook-media-link {
          max-width: 15px;
        }

        svg {
          display: block;
          width: 100%;
        }
      }
    }

    @media (max-width: $md-break) {
      flex-direction: column;
      border-bottom: none;

      .header-logo-container {
        width: 100%;
        padding-bottom: 40px;
        border-bottom: 1.5px solid $light;

        img {
          margin: 0 auto;
        }
      }

      .social-links {
        padding-top: 40px;
        max-width: 250px;
        margin: 0 auto;
      }
    }
  }

  .footer-bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;



    .footer-legit {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: $light;
    }

    .footer-nav {
      display: flex;
      align-items: center;

      .primary-btn {
        @media (max-width: 980px) {
          display: none;
        }
      }

      .footer-nav-link {
        position: relative;
        font-family: $fontTitle;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: $light;
        margin-right: 40px;
        transition: color ease-out 0.2s;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          color: $white;
        }

        &.is-active {
          color: $white;

          &:after {
            content: '';
            position: absolute;
            width: 4px;
            height: 4px;
            border-radius: 4px;
            background-color: $colorAccent1;
            left: 50%;
            bottom: -10px;
            transform: translateX(-50%);
          }
        }

        @media (max-width: 980px) {
          display: none;

          &.on-display {
            display: block;
          }
        }

        @media (max-width: $md-break) {
          &.on-display {
            display: none;
          }
        }
      }
    }

    @media (max-width: $md-break) {
      flex-direction: column;
      padding-top: 0;

      .footer-legit {
        width: 100%;
        text-align: center;
      }
    }
  }
}
