.terms-entry-wrapper {
  background: $white;

  .terms-wrapper {
    padding-top: 130px;
    padding-bottom: 190px;

    h1,
    h2,
    h3 {
      font-family: Manrope;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 49px;
      letter-spacing: 0em;
      text-align: left;
      margin-top: 0;
      margin-bottom: 17px;
    }

    p {
      font-family: SF Pro Display;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      color: $light;
      margin-top: 0;
      margin-bottom: 84px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.cookies-wrapper {
  *:not(a) {
    font-family: Manrope !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 28px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    color: $white !important;
  }

  a {
    font-family: Manrope !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 28px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    text-decoration: underline !important;
    color: $colorAccent1 !important;
  }
}
