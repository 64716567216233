.block-contact {
  width: 100%;
  background-color: $colorAccent1;
  padding-top: 164px;
  padding-bottom: 164px;


  @media (max-width: $md-break) {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .classique-form-texts {
    display: none;
    &.is-active {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 87.5px;
    padding-bottom: 48px;
    border-bottom: 1px solid $dark;
    border-top: 1px solid $dark;

    .contact-title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-top: 0;
      margin-bottom: 44px;

      @media (max-width: $lg-break) {
        flex-direction: column;

        span {
          margin: 5px 0;
        }
      }

      span {
        margin: 0 10px;
      }
    }

    .contact-btn {
      margin: 0 auto;
    }
  }
}

.contact-form-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9979;
  background-color: rgba($dark, .35);
  display: none;

  &.is-active {
    display: block;
  }
}

.close-contact-btn {
  font-family: Manrope;
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  /* Black */

  color: #151515;
  text-align: right;
  margin-top: 0;
  margin-bottom: 25px;
  cursor: pointer;

  &:after {
    content: '';
    height: 1.5px;
    width: 32px;
    background-color: #151515;
    position: absolute;
    bottom: -4px;
    right: 15px;

  }
}

.contact-form {
  position: fixed;
  bottom: -100vh;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;

  background-color: $colorAccent1;
  padding-top: 97px;
  padding-bottom: 97px;
  z-index: 9999;

  &.is-active {
    bottom: 0;
    transition: bottom .35s ease-in-out;
  }

  @media (max-width: $md-break) {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .content {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 87.5px;
    padding-bottom: 48px;
    border-bottom: 1px solid $dark;
    border-top: 1px solid $dark;

    .thank-you {
      position: absolute;
      top: -100%;
      left: -100%;
      z-index: 9999;
      opacity: 0;
      transition: opacity .35s ease-in-out;

      &.is-active {
        opacity: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      h2 {
        font-family: $fontTitle;
        font-size: 72px;
        font-style: normal;
        font-weight: 600;
        line-height: 86px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 0;
        margin-bottom: 26px;
      }

      p {
        font-family: $fontTitle;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 49px;
        letter-spacing: 0em;
        text-align: center;
        margin: 0;

      }
    }

    form {
      position: relative;
      z-index: 5;
      width: 100%;
      max-width: 630px;
      margin-left: auto;
      margin-right: auto;
      opacity: 1;
      transition: opacity .35s ease-in-out;

      &.is-active {
        opacity: 0;
      }


      ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        font-family: $fontTitle;
        font-size: 36px;
        line-height: 49px;
        color: rgba($colorAccent2, .5);
        margin: 0;
        font-weight: 600;

        @media (max-width: $md-break) {
          font-size: 24px;
          line-height: 33px;
        }
      }

      ::-moz-placeholder { /* Firefox 19+ */
        font-family: $fontTitle;
        font-size: 36px;
        line-height: 49px;
        color: rgba($colorAccent2, .5);
        margin: 0;
        font-weight: 600;

        @media (max-width: $md-break) {
          font-size: 24px;
          line-height: 33px;
        }
      }

      :-ms-input-placeholder { /* IE 10+ */
        font-family: $fontTitle;
        font-size: 36px;
        line-height: 49px;
        color: rgba($colorAccent2, .5);
        margin: 0;
        font-weight: 600;

        @media (max-width: $md-break) {
          font-size: 24px;
          line-height: 33px;
        }
      }

      :-moz-placeholder { /* Firefox 18- */
        font-family: $fontTitle;
        font-size: 36px;
        line-height: 49px;
        color: rgba($colorAccent2, .5);
        margin: 0;
        font-weight: 600;

        @media (max-width: $md-break) {
          font-size: 24px;
          line-height: 33px;
        }
      }


    .form-message {
      margin-bottom: 40px;

      a {
        display: inline-block;
      }
    }

    .input-wrapper {
      position: relative;
      width: 100%;

      &.for-textarea {
        &:before {
          transform: translate3d(0, 2.25rem, 0);
        }
      }

      input,
      textarea {
        width: 100%;
        margin: 1rem 0 1rem 0;
        padding-left: 1.5rem;
        background: transparent;
        border: none;
        outline: none;
        resize: none;
        color: $dark;
        line-height: 2.5;

        font-family: $fontTitle;
        font-size: 36px;
        line-height: 49px;
        color: rgba($colorAccent2, 1);
        font-weight: 600;

        margin-bottom: 66px;
        margin-top: 0;

        @media (max-width: $md-break) {
          font-size: 24px;
          line-height: 33px;
        }

        &:not(:placeholder-shown), &:focus {
          margin-bottom: 46px;

          & + label {

            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 33px;
            letter-spacing: 0em;
            text-align: left;

            top: -28px;
            transform: translateY(0);
            color: $dark;
            opacity: .5;
          }
        }
      }


      label {
        position: absolute;
        top: 15px;
        left: 0;
        z-index: -1;
        color: rgba($dark, 0.5);
        padding-left: 1.5rem;
        background: transparent;
        border: none;


        font-family: $fontTitle;
        font-size: 36px;
        line-height: 49px;

        font-weight: 600;

        opacity: 1;
        pointer-events: none;
        transition: all ease-out .2s;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
          width: 8px;
          height: 8px;
          background: $dark;
          border-radius: 50%;
        }


        @media (max-width: $md-break) {
          font-size: 24px;
          line-height: 33px;
        }


      }

      textarea {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 2.5;
        letter-spacing: 0em;
      }

      .input-error {
        display: none;
        position: absolute;
        left: 0;
        bottom: 80%;
        width: 70vw;
        font-size: 14px;
        color: red;
        font-weight: 700;

        &.is-active {
          display: block;
        }
      }
    }

      .form-btn {
        border-color: $dark;
        color: $dark;
        margin-right: 0;
        margin-left: auto;

        &:hover {
          background-color: $dark;
          color: $colorAccent1;
        }
      }
    }

    .contact-title {
      display: block;
      text-align: center;
      width: 100%;
      margin-top: 0;
      margin-bottom: 44px;
    }

    .contact-btn {
      margin: 0 auto;
    }
  }
}

.contact-form-cta {
  display: none;

  &.is-active {
    display: block;
    width: 100%;
  }

  .content-form {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .thank-you-classique {
      position: absolute;
      top: -100%;
      left: -100%;
      z-index: 9999;
      opacity: 0;
      transition: opacity .35s ease-in-out;

      &.is-active {
        opacity: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      h2 {
        font-family: $fontTitle;
        font-size: 72px;
        font-style: normal;
        font-weight: 600;
        line-height: 86px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 0;
        margin-bottom: 26px;
      }

      p {
        font-family: $fontTitle;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 49px;
        letter-spacing: 0em;
        text-align: center;
        margin: 0;

      }
    }

    form {
      position: relative;
      z-index: 5;
      width: 100%;
      max-width: 630px;
      margin-left: auto;
      margin-right: auto;
      opacity: 1;
      transition: opacity .35s ease-in-out;

      &.is-active {
        opacity: 0;
      }

      ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        font-family: $fontTitle;
        font-size: 36px;
        line-height: 49px;
        color: rgba($colorAccent2, .5);
        margin: 0;
        font-weight: 600;

        @media (max-width: $md-break) {
          font-size: 24px;
          line-height: 33px;
        }
      }

      ::-moz-placeholder { /* Firefox 19+ */
        font-family: $fontTitle;
        font-size: 36px;
        line-height: 49px;
        color: rgba($colorAccent2, .5);
        margin: 0;
        font-weight: 600;

        @media (max-width: $md-break) {
          font-size: 24px;
          line-height: 33px;
        }
      }

      :-ms-input-placeholder { /* IE 10+ */
        font-family: $fontTitle;
        font-size: 36px;
        line-height: 49px;
        color: rgba($colorAccent2, .5);
        margin: 0;
        font-weight: 600;

        @media (max-width: $md-break) {
          font-size: 24px;
          line-height: 33px;
        }
      }

      :-moz-placeholder { /* Firefox 18- */
        font-family: $fontTitle;
        font-size: 36px;
        line-height: 49px;
        color: rgba($colorAccent2, .5);
        margin: 0;
        font-weight: 600;

        @media (max-width: $md-break) {
          font-size: 24px;
          line-height: 33px;
        }
      }

      .form-message {
        margin-bottom: 70px;

        a {
          display: inline-block;
        }
      }

      .input-wrapper {
        position: relative;
        width: 100%;

        &.for-textarea {
          &:before {
            transform: translate3d(0, 2.25rem, 0);
          }
        }

        input,
        textarea {
          width: 100%;
          margin: 1rem 0 1rem 0;
          padding-left: 1.5rem;
          background: transparent;
          border: none;
          outline: none;
          resize: none;
          color: $dark;
          line-height: 2.5;

          font-family: $fontTitle;
          font-size: 36px;
          line-height: 49px;
          color: rgba($colorAccent2, 1);
          font-weight: 600;

          margin-bottom: 66px;

          margin-top: 0;

          @media (max-width: $md-break) {
            font-size: 24px;
            line-height: 33px;
          }

          &:not(:placeholder-shown), &:focus {
            margin-bottom: 46px;

            & + label {

              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: 33px;
              letter-spacing: 0em;
              text-align: left;

              top: -28px;
              transform: translateY(0);
              color: $dark;
              opacity: .5;
            }
          }
        }

        label {
          position: absolute;
          top: 15px;
          left: 0;
          z-index: -1;
          color: rgba($dark, 0.5);
          padding-left: 1.5rem;
          background: transparent;
          border: none;


          font-family: $fontTitle;
          font-size: 36px;
          line-height: 49px;

          font-weight: 600;

          opacity: 1;
          pointer-events: none;
          transition: all ease-out .2s;

          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            width: 8px;
            height: 8px;
            background: $dark;
            border-radius: 50%;
          }


          @media (max-width: $md-break) {
            font-size: 24px;
            line-height: 33px;
          }
        }

        textarea {
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 2.5;
          letter-spacing: 0em;
        }

        .input-error {
          display: none;
          position: absolute;
          left: 0;
          bottom: 80%;
          width: 70vw;
          font-size: 14px;
          color: red;
          font-weight: 700;

          &.is-active {
            display: block;
          }
        }
      }

      .form-btn {
        border-color: $dark;
        color: $dark;
        margin-right: 0;
        margin-left: auto;

        &:hover {
          background-color: $dark;
          color: $colorAccent1;
        }
      }
    }

    .contact-title {
      display: block;
      text-align: center;
      width: 100%;
      margin-top: 0;
      margin-bottom: 44px;
    }

    .contact-btn {
      margin: 0 auto;
    }
  }
}
