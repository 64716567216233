.apply-btn {
  display: block;
  width: 100%;
  max-width: 150px;
  position: fixed;
  right: 146px;
  bottom: 250px;
  z-index: 99;

  @media (max-width: 1226px) {
    max-width: 100px;
    right: 56px;
    bottom: 50px;
  }

  @media (max-width: 767px) {
    max-width: 70px;
    right: 146px;
    bottom: 250px;
  }
}

.hoffman-life-blog-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 0 64px;

  @media (max-width: 1023px) {
    padding: 50px 0;
  }

  .content-blocks {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
      margin-bottom: 100px;

      @media (max-width: 1023px) {
        margin-bottom: 50px;
      }
    }

    .content-citation {
      position: relative;
      width: 100%;
      max-width: calc(1120px + 2 * 40px);
      padding: 0 40px 0 140px;

      @media (max-width: 767px) {
        padding: 75px 16px 0;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 40px;
        width: 50px;
        height: 40px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top left;
        background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MCA0MiI+PHBhdGggZD0ibTQ3IDM5Ljk1LS45MSAxLjY5Yy0xLjgyLTEuMjEzLTMuODEzLTIuNi01Ljk4LTQuMTZhMzMuODggMzMuODggMCAwIDEtNS45OC01LjJjLTEuODItMS45OTMtMy4zOC00LjI5LTQuNjgtNi44OS0xLjIxMy0yLjYtMS44Mi01LjU5LTEuODItOC45NyAwLTQuOTQgMS4xNy04Ljc1MyAzLjUxLTExLjQ0QzMzLjQ4IDIuMjk1IDM2LjI5OC45NSAzOS41OS45NWMxLjEyNyAwIDIuMjU0LjE3NCAzLjM4LjUyIDEuMjE0LjM0NyAyLjI5Ny44NjcgMy4yNSAxLjU2YTkuNDIyIDkuNDIyIDAgMCAxIDIuMzQgMi44NmMuNjA3IDEuMjE0LjkxIDIuNjQ0LjkxIDQuMjkgMCAxLjY0Ny0uMzAzIDMuMDM0LS45MSA0LjE2LS42MDYgMS4yMTQtMS4zNDMgMi4xNjctMi4yMSAyLjg2YTguMDU5IDguMDU5IDAgMCAxLTIuODYgMS42OWMtMS4wNC4zNDctMi4wMzYuNTItMi45OS41Mi0uOTUzIDAtMS45MDYtLjA4Ni0yLjg2LS4yNmE2LjgzOSA2LjgzOSAwIDAgMS0yLjYtMS4wNGMtLjQzMy40MzQtLjg2NiAxLjA4NC0xLjMgMS45NS0uNDMzLjg2Ny0uNjUgMS45OTQtLjY1IDMuMzggMCAzLjEyIDEuMjU3IDUuODk0IDMuNzcgOC4zMiAyLjUxNCAyLjUxNCA1Ljg5NCA1LjI0NCAxMC4xNCA4LjE5Wm0tMjYuOTEgMC0uOTEgMS42OWMtMS44Mi0xLjIxMy0zLjgxMy0yLjYtNS45OC00LjE2YTMzLjg4IDMzLjg4IDAgMCAxLTUuOTgtNS4yYy0xLjgyLTEuOTkzLTMuMzgtNC4yOS00LjY4LTYuODlDMS4zMjggMjIuNzkuNzIgMTkuOC43MiAxNi40MmMwLTQuOTQgMS4xNy04Ljc1MyAzLjUxLTExLjQ0QzYuNTcgMi4yOTUgOS4zODguOTUgMTIuNjguOTVjMS4xMjcgMCAyLjI1NC4xNzQgMy4zOC41MiAxLjIxNC4zNDcgMi4yOTcuODY3IDMuMjUgMS41NmE5LjQyMiA5LjQyMiAwIDAgMSAyLjM0IDIuODZjLjYwNyAxLjIxNC45MSAyLjY0NC45MSA0LjI5IDAgMS42NDctLjMwMyAzLjAzNC0uOTEgNC4xNi0uNjA2IDEuMjE0LTEuMzQzIDIuMTY3LTIuMjEgMi44NmE4LjA1OSA4LjA1OSAwIDAgMS0yLjg2IDEuNjljLTEuMDQuMzQ3LTIuMDM2LjUyLTIuOTkuNTItLjk1MyAwLTEuOTA2LS4wODYtMi44Ni0uMjZhNi44MzkgNi44MzkgMCAwIDEtMi42LTEuMDRjLS40MzMuNDM0LS44NjYgMS4wODQtMS4zIDEuOTUtLjQzMy44NjctLjY1IDEuOTk0LS42NSAzLjM4IDAgMy4xMiAxLjI1NyA1Ljg5NCAzLjc3IDguMzIgMi41MTQgMi41MTQgNS44OTQgNS4yNDQgMTAuMTQgOC4xOVoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=");

        @media (max-width: 767px) {
          left: 16px;
        }
      }

      .citation-content {
        font-family: $fontTitle;
        font-style: normal;
        font-weight: 300;
        font-size: 36px;
        line-height: 135%;
        text-transform: uppercase;
        color: $white;
        margin: 0;

        @media (max-width: 1023px) {
          font-size: 26px;
        }
      }

      .citation-author {
        margin: 20px 0 0;
        font-weight: 300;
        font-size: 18px;
        line-height: 155%;
        color: $white;
      }
    }

    .content-gallery {
      width: 100%;
      max-width: calc(1120px + 2 * 40px);
      padding: 0 40px;
      display: flex;
      flex-wrap: wrap;

      @media (max-width: 767px) {
        padding: 0 16px;
      }

      .gallery-image {
        width: calc((100% - 160px) / 3);
        margin-right: 80px;
        margin-top: 80px;

        @media (max-width: 1023px) {
          width: calc((100% - 32px) / 3);
          margin-right: 16px;
          margin-top: 16px;
        }

        @media (max-width: 767px) {
          width: 100%;
          margin-right: 0;
          margin-top: 16px;
        }

        @media (min-width: 768px) {
          &:nth-child(1), &:nth-child(2), &:nth-child(3) {
            margin-top: 0;
          }
        }

        &:nth-child(3n+3) {
          margin-right: 0;
        }
      }
    }

    .content-image-container {
      width: 100%;
      display: flex;

      @media (max-width: 767px) {
        padding: 0;
      }

      &:not(.fullscreen) {
        max-width: calc(1120px + 2 * 40px);
        padding: 0 40px;

        @media (max-width: 767px) {
          padding: 0 16px;
        }
      }

      .content-image {
        width: 100%;
      }
    }

    .content-split-container {
      width: 100%;
      padding: 0 40px;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      position: relative;

      &:not(.fullscreen) {
        max-width: calc(1120px + 2 * 40px);
      }

      @media (max-width: 1023px) {
        flex-direction: column-reverse !important;
      }

      @media (max-width: 767px) {
        padding: 0 16px;
      }

      @media (min-width: 1024px) {
        &.fullscreen {
          padding: 0;
          justify-content: center;

          .split-content-container {
            width: 100%;
            max-width: calc(1120px + 2 * 40px);
            padding: 0 40px;
            &.text-center {
              justify-content: center;
            }
            & > * {
              width: 50%;
            }
          }

          .split-image-container{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: calc(50% - 100px);
          }
        }
      }

      &.reversed {
        flex-direction: row-reverse;

        &.fullscreen {
          .split-content-container {
            align-items: flex-end;
            &.text-center {
              justify-content: center;
            }
          }

          .split-image-container {
            right: initial;
            left: 0;
          }
        }
      }

      .split-content-container {
        width: calc(50% - 40px);
        display: flex;
        flex-direction: column;
        &.text-center {
          justify-content: center;
          h3 {
            strong {
              font-weight: 600;
            }
          }
        }

        @media (max-width: 1023px) {
          width: 100%;
        }

        & > * {
          margin: 0 0 40px;

          @media (max-width: 1023px) {
            width: 100%;
          }

          @media (max-width: 767px) {
            margin: 0 0 30px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        p {
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 155%;
          color: $white;
          font-family: $fontTitle;

          strong {
            font-weight: 700;
          }
        }

        ul {
          margin: -35px 0 5px;
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 155%;
          color: $white;
          padding-left: 30px;
        }

        h3 {
          font-family: $fontTitle;
          font-style: normal;
          font-weight: 600;
          font-size: 30px;
          line-height: 135%;
          color: $white;
          margin-bottom: 40px;

          @media (max-width: 767px) {
            margin-bottom: 20px;
          }
        }

        h4 {
          font-family: $fontTitle;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 135%;
          color: $white;
          margin-bottom: 40px;

          @media (max-width: 767px) {
            margin-bottom: 20px;
          }
        }

        h5 {
          font-family: $fontTitle;
          font-style: normal;
          font-weight: 300;
          font-size: 24px;
          line-height: 135%;
          color: $white;
          margin-bottom: 40px;

          @media (max-width: 767px) {
            margin-bottom: 20px;
          }
        }
      }

      .split-image-container {
        width: calc(50% - 40px);
        display: flex;

        @media (max-width: 1023px) {
          position: relative;
          width: 100%;
          height: 56vw;
          margin-bottom: 50px;
        }

        @media (max-width: 767px) {
          height: 138vw;
        }

        .split-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .content-text-container {
      width: 100%;
      max-width: calc(1120px + 2 * 40px);
      padding: 0 40px;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      position: relative;

      .text-content-container {
        width: 100%;
        display: flex;
        flex-direction: column;

        & > * {
          margin: 0 0 40px;

          @media (max-width: 1023px) {
            width: 100%;
          }

          @media (max-width: 767px) {
            margin: 0 0 30px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        p {
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 155%;
          color: $white;
          font-family: $fontTitle;

          strong {
            font-weight: 700;
          }
        }

        ul {
          margin: -35px 0 5px;
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 155%;
          color: $white;
          padding-left: 30px;
        }

        h3 {
          font-family: $fontTitle;
          font-style: normal;
          font-weight: 600;
          font-size: 30px;
          line-height: 135%;
          color: $white;
          margin-bottom: 40px;

          @media (max-width: 767px) {
            margin-bottom: 20px;
          }
        }

        h4 {
          font-family: $fontTitle;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 135%;
          color: $white;
          margin-bottom: 40px;

          @media (max-width: 767px) {
            margin-bottom: 20px;
          }
        }

        h5 {
          font-family: $fontTitle;
          font-style: normal;
          font-weight: 300;
          font-size: 24px;
          line-height: 135%;
          color: $white;
          margin-bottom: 40px;

          @media (max-width: 767px) {
            margin-bottom: 20px;
          }
        }
      }

      .split-image-container {
        width: calc(50% - 40px);
        display: flex;

        @media (max-width: 1023px) {
          position: relative;
          width: 100%;
          height: 56vw;
          margin-bottom: 50px;
        }

        @media (max-width: 767px) {
          height: 138vw;
        }

        .split-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .content-top3-container {
      width: 100%;
      max-width: calc(1120px + 2 * 40px);
      padding: 0 40px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: 767px) {
        padding: 0 16px;
      }

      .top3-title {
        width: 100%;
        margin: 0 0 100px;
        color: $white;
        font-weight: 600;

        &.top-job-title {
          font-family: $fontTitle;
          font-size: 72px;
          font-weight: 600;
          line-height: 86px;
          letter-spacing: 0em;
          text-align: left;

          @media (max-width: 767px) {
            font-size: 36px;
            line-height: 49px;
          }

          p {
            em {
              background-color: $departmentBlue;
              font-style: normal;
            }
          }
        }

        @media (max-width: 1023px) {
          margin: 0 0 40px;
        }
      }

      .top3-container {
        width: 100%;
        max-width: 736px;
        display: flex;
        flex-direction: column;

        .top3-item {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          margin-bottom: 50px;

          @media (max-width: 767px) {
            flex-direction: column !important;
            margin-bottom: 40px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &:nth-child(even) {
            flex-direction: row-reverse;

            .top3-item-content {
              &:before {
                background-position: top left;
              }

              .top3-item-title, .top3-item-text {
                text-align: left;
              }
            }
          }

          &:nth-child(1) .top3-item-content:before {background-image: url('/dist/img/slider-1.svg');}
          &:nth-child(2) .top3-item-content:before {background-image: url('/dist/img/slider-2.svg');}
          &:nth-child(3) .top3-item-content:before {background-image: url('/dist/img/slider-3.svg');}
          &:nth-child(4) .top3-item-content:before {background-image: url('/dist/img/slider-4.svg');}
          &:nth-child(5) .top3-item-content:before {background-image: url('/dist/img/slider-5.svg');}
          &:nth-child(6) .top3-item-content:before {background-image: url('/dist/img/slider-6.svg');}

          .top3-item-content {
            position: relative;
            width: calc(50% - 16px);
            display: flex;
            flex-direction: column;

            &.top3-item-content-center { 
              align-self: center;
            }

            @media (max-width: 767px) {
              width: 100%;
              margin-bottom: 20px;
            }

            &:before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              height: 160px;
              background-repeat: no-repeat;
              background-position: top right;
              background-size: contain;
              z-index: -1;

              @media (max-width: 767px) {
                background-position: top left;
              }
            }

            .top3-item-title {
              text-align: right;
              color: $white;
              margin: 60px 0 30px;
              font-weight: 400;

              @media (max-width: 767px) {
                text-align: left;
              }
            }

            .top3-item-text {
              text-align: right;
              color: $white;
              margin: 0;

              @media (max-width: 767px) {
                text-align: left;
              }
            }
          }

          .top3-item-image-container {
            width: calc(50% - 16px);

            @media (max-width: 767px) {
              width: 100%;
            }

            .top3-item-image {
              width: 100%;
            }
          }
        }
      }
    }
    .content-top6-container {
      width: 100%;
      max-width: calc(1120px + 2 * 40px);
      padding: 0 40px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: 767px) {
        padding: 0 16px;
      }

      .top6-title {
        width: 100%;
        margin: 0 0 100px;
        color: $white;

        &.top-job-title {
          font-family: $fontTitle;
          font-size: 72px;
          font-weight: 600;
          line-height: 86px;
          letter-spacing: 0em;
          text-align: left;

          @media (max-width: 767px) {
            font-size: 36px;
            line-height: 49px;
          }

          p {
            em {
              background-color: $departmentBlue;
              font-style: normal;
            }
          }
        }

        @media (max-width: 1023px) {
          margin: 0 0 40px;
        }
      }

      .top6-container {
        width: 100%;
        max-width: 736px;
        display: flex;
        flex-direction: column;

        .top6-item {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          margin-bottom: 50px;

          @media (max-width: 767px) {
            flex-direction: column !important;
            margin-bottom: 40px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &:nth-child(even) {
            flex-direction: row-reverse;

            .top6-item-content {
              &:before {
                background-position: top left;
              }

              .top6-item-title, .top6-item-text {
                text-align: left;
              }
            }
          }

          &:nth-child(1) .top6-item-content:before {background-image: url('/dist/img/slider-1.svg');}
          &:nth-child(2) .top6-item-content:before {background-image: url('/dist/img/slider-2.svg');}
          &:nth-child(3) .top6-item-content:before {background-image: url('/dist/img/slider-3.svg');}
          &:nth-child(4) .top6-item-content:before {background-image: url('/dist/img/slider-4.svg');}
          &:nth-child(5) .top6-item-content:before {background-image: url('/dist/img/slider-5.svg');}
          &:nth-child(6) .top6-item-content:before {background-image: url('/dist/img/slider-6.svg');}

          .top6-item-content {
            position: relative;
            width: calc(50% - 16px);
            display: flex;
            flex-direction: column;
            margin-top: 80px;

            @media (max-width: 767px) {
              width: 100%;
              margin-bottom: 70px;
              margin-top: 0;
            }

            &:before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              height: 160px;
              background-repeat: no-repeat;
              background-position: top right;
              background-size: contain;
              z-index: -1;

              @media (max-width: 767px) {
                background-position: top left;
              }
            }

            .top6-item-title {
              text-align: right;
              color: $white;
              margin: 60px 0 30px;

              @media (max-width: 767px) {
                text-align: left;
              }
            }

            .top6-item-text {
              text-align: right;
              color: $white;
              margin: 0;

              @media (max-width: 767px) {
                text-align: left;
              }
            }
          }

          .top6-item-image-container {
            width: calc(50% - 16px);

            @media (max-width: 767px) {
              width: 100%;
            }

            .top6-item-image {
              width: 100%;
            }
          }
        }
      }
    }

    .content-list-one {
      width: 100%;
      max-width: calc(1120px + 2 * 40px);
      padding: 0 40px;

      @media (max-width: 767px) {
        padding: 0 16px;
      }

      .list-one-title {
        display: block;
        width: 100%;
        color: $white;
        font-family: $fontTitle;
        font-size: 72px;
        font-weight: 600;
        line-height: 86px;
        letter-spacing: 0em;
        text-align: left;

        @media (max-width: 767px) {
          font-size: 36px;
          line-height: 49px;
        }

      }

      .list-one-container {
        display: flex;
        flex-direction: column;

        .list-one-item {
          display: flex;
          padding: 35px 0;
          border-bottom: 1px solid $white;

          @media (max-width: 767px) {
            padding: 15px 0;
          }

          &:first-child {
            border-top: 1px solid $white;
          }

          svg {
            display: block;
            width: 100%;
            max-width: 40px;
            margin-right: 55px;
          }

          p {
            display: block;
            width: 100%;
            color: $white;
            font-family: $fontText;
            font-size: 24px;
            font-weight: 600;
            line-height: 34px;
            letter-spacing: 0em;
            text-align: left;

            @media (max-width: 767px) {
              font-size: 18px;
              line-height: 28px;
            }
          }
        }
      }
    }

    .content-list-two {
      width: 100%;
      max-width: calc(1120px + 2 * 40px);
      padding: 0 40px;

      @media (max-width: 767px) {
        padding: 0 16px;
      }

      .list-two-title {
        display: block;
        width: 100%;
        color: $white;
        font-family: $fontTitle;
        font-size: 72px;
        font-weight: 600;
        line-height: 86px;
        letter-spacing: 0em;
        text-align: left;

        @media (max-width: 767px) {
          font-size: 36px;
          line-height: 49px;
        }

        p {
          em {
            background-color: $departmentBlue;
            font-style: normal;
          }
        }

      }

      .list-two-container {
        display: flex;
        flex-direction: column;

        .list-two-item {
          display: flex;
          padding: 35px 0;
          border-bottom: 1px solid $white;

          @media (max-width: 767px) {
            padding: 15px 0;
          }

          &:first-child {
            border-top: 1px solid $white;
          }

          svg {
            display: block;
            width: 100%;
            max-width: 40px;
            margin-right: 55px;
          }

          p {
            display: block;
            width: 100%;
            color: $white;
            font-family: $fontText;
            font-size: 24px;
            font-weight: 600;
            line-height: 34px;
            letter-spacing: 0em;
            text-align: left;

            @media (max-width: 767px) {
              font-size: 18px;
              line-height: 28px;
            }
          }
        }
      }
    }

    .content-slider-image {
      width: 100%;

      .slider-image-container {
        display: flex;
        overflow: scroll;

        .slide-image {
          display: block;
          width: 100%;
          height: 100%;
          margin-right: 31px;

          @media (max-width: 767px) {
            max-width: 300px;
            margin-right: 18px;
          }

          &:first-child {
            margin-left: 31px;

            @media (max-width: 767px) {
              margin-left: 18px;
            }
          }
        }
      }
    }
  




  }

  .hero-back-link-container {
    width: 100%;
    max-width: calc(1120px + 2 * 40px);
    padding: 0 40px 0 0;
    display: flex;

    @media (max-width: 767px) {
      padding: 0 16px;
    }

    .hero-back-link {
      display: flex;
      align-items: center;


      svg {
        height: 12px;
        margin-right: 10px;
      }

      span {
        font-family: $fontTitle;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 135%;
        color: $colorAccent1;
      }
    }
  }
}
