.hoffman-life-video {
  width: 100%;
  display: flex;
  justify-content: center;

  .logo-video {
    width: 100%;

    @media (max-width: 1023px) {
      display: none;
    }
  }

  .logo-gif {
    display: none;
    width: 100%;

    @media (max-width: 1023px) {
      display: block;
    }
  }
}
