.block-values {
  background-color: $white;
  padding-top: 164px;
  padding-bottom: 128px;

  overflow: hidden;

  @media (max-width: $md-break) {
    padding-top: 130px;
    padding-bottom: 85px;
  }

  @media (max-width: $sm-break) {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .content-container {
    max-width: 736px;

    @media (max-width: $md-break) {
      max-width: 547px;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;



    .values-title {
      display: block;
      width: 100%;
      margin-bottom: 63px;

      @media (max-width: $md-break) {
        margin-bottom: 56px;
      }
    }

    .content-text {
      display: block;
      max-width: 736px;

      p {
        margin: 0;
      }

      @media (max-width: $md-break) {
        width: 100%;
      }
    }
  }

  .values-slides-wrapper {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 136px;
    max-width: 736px;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      right: 100%;
      bottom: 0;
      width: 2000px;
      background-color: white;
      z-index: 2;
    }

    @media (max-width: $md-break) {
      max-width: 547px;
    }

    @media (max-width: $sm-break) {
      width: 100%;
      max-width: 100%;
    }

    .flickity-viewport {
      overflow: visible;
    }

    .slider-card {
      display: block;
      width: 100%;
      max-width: 533px;
      position: relative;
      margin: 0 16px;

      @media (max-width: $md-break) {
        max-width: 355px;
        margin-right: 20px;
      }

      @media (max-width: $sm-break) {
        max-width: 229px;
        margin-right: 20px;
      }

      &.is-selected {
        .img-number {
          opacity: 0;
          display: none;
        }

        .img-number-full {
          opacity: 1;
          display: block;
        }
      }

      .img-number {
        position: absolute;
        min-width: 190px;
        opacity: 1;
        z-index: 1;
        top: 0;
        left: 0;

        @media (max-width: $sm-break) {
          position: relative;
          min-width: 10px;
          max-height: 80px;
        }
      }

      .img-number-full {
        display: none;
        position: absolute;
        min-width: 190px;
        opacity: 0;
        z-index: 1;
        top: 0;
        left: 0;

        @media (max-width: $sm-break) {
          position: relative;
          min-width: 10px;
          max-height: 80px;
        }
      }

      .card-title {
        position: relative;
        margin-top: 60px;
        margin-bottom: 30px;
        margin-left: 180px;
        z-index: 2;

        @media (max-width: $md-break) {
          width: 100%;
          margin-left: 0px;
        }

        @media (max-width: $sm-break) {
          width: 100%;
          margin-left: 0px;
          margin-top: 20px;
          font-size: 30px;
          line-height: 41px;
        }
      }

      .card-text {
        position: relative;
        color: #959595;
        margin-left: 180px;
        margin-bottom: 0;
        z-index: 2;

        @media (max-width: $md-break) {
          width: 100%;
          margin-left: 0px;
        }

        @media (max-width: $sm-break) {
          width: 100%;
          margin-left: 0px;
          font-weight: 300;
          font-size: 18px;
          line-height: 155%;
        }

        p {
          margin: 0;
        }
      }
    }
  }
}

