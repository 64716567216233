/**
 * components/typography.css
 *
 * Typography rules.
 *
 */

html {
  font-family: $fontText;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
}

.h1-head {
  font-family: $fontTitle;
  font-style: normal;
  font-weight: 600;
  font-size: 96px;
  line-height: 110%;
  color: $colorAccent2;
  margin: 0;
}

.h2-head {
  font-family: $fontTitle;
  font-weight: 600;
  font-size: 72px;
  line-height: 98px;
  color: $colorAccent2;
  margin: 0;

  @media (max-width: $md-break) {
    font-size: 40px;
    line-height: 55px;
  }
}

.h3-title {
  font-family: $fontTitle;
  font-size: 36px;
  line-height: 49px;
  color: $colorAccent2;
  margin: 0;

  &.h3-bold {
    font-weight: bold;
  }

  &.h3-semibold {
    font-weight: 600;
  }

  &.h3-regular {
    font-weight: normal;
  }

  @media (max-width: $md-break) {
    font-size: 24px;
    line-height: 33px;
  }
}

.h4-title {
  font-family: $fontTitle;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: $colorAccent2;
  margin: 0;

  &.h4-bold {
    font-weight: bold;
  }

  &.h4-semibold {
    font-weight: 600;
  }

  &.h4-regular {
    font-weight: normal;
  }

  @media (max-width: $md-break) {
    font-size: 18px;
    line-height: 155%;
  }
}

.h5-title {
  font-family: $fontTitle;
  font-size: 24px;
  line-height: 33px;
  color: $colorAccent2;
  margin: 0;

  &.h5-semibold {
    font-weight: 600;
  }

  &.h5-regular {
    font-weight: normal;
  }

  @media (max-width: $md-break) {
    font-size: 24px;
    line-height: 33px;
  }
}

.regular-text {
  font-family: $fontText;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  color: #151515;
  margin: 0;

  @media (max-width: $md-break) {
    font-size: 24px;
  }
}

.light-text {
  font-family: $fontText;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 140%;
  color: #151515;
  margin: 0;

  strong {
    font-weight: 600;
  }

  @media (max-width: $md-break) {
    font-size: 24px;
  }
}

.semibold-text {
  font-family: $fontText;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  color: #151515;
  margin: 0;

  @media (max-width: $md-break) {
    font-size: 24px;
  }
}

.small-text {
  font-family: $fontText;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 155%;
  color: #151515;
  margin: 0;
}

.subtitle {
  font-family: $fontText;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #151515;
  margin: 0;
}
