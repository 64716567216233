.hoffman-life-spontaneous {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $white;

  .spontaneous-container {
    width: 100%;
    max-width: calc(1110px + (2 * 40px));
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 120px;
    padding-bottom: 120px;

    @media (max-width: 1023px) {
      padding-top: 60px;
      padding-bottom: 60px;
      flex-direction: column;
    }

    @media (max-width: 767px) {
      padding: 40px 16px;
    }

    .spontaneous-heading {
      width: 485px;
      flex-shrink: 0;
      font-family: $fontTitle;
      font-style: normal;
      font-weight: 600;
      font-size: 72px;
      line-height: 120%;
      color: $colorAccent2;
      margin: 0 90px 0 0;

      @media (max-width: 1023px) {
        width: 100%;
        margin: 0 0 40px;
        font-size: 50px;
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 20px;
        font-size: 40px;
      }
    }

    .spontaneous-right-side {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .spontaneous-text {
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 140%;
        color: $light;
        margin: 0 0 16px;

        @media (max-width: 767px) {
          margin-bottom: 30px;
        }
      }
    }
  }
}
